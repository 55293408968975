import { inject, Injectable } from '@angular/core'
import { NavigationEnd, NavigationStart, Router } from '@angular/router'
import { addAnalyticsProps, AnalyticsService } from '@app/analytics/analytics.service'
import { horizontalSlideAnimation } from '@app/animations/horizontal-slide'
import { verticalSlideAnimation } from '@app/animations/vertical-slide'
import { analyticsRoutesMap, ExternalApp, ROUTES } from '@app/cnst/nav.cnst'
import { NavigationParams } from '@app/cnst/nav.params.cnst'
import { isAndroidApp, isIOSApp } from '@app/cnst/userDevice.cnst'
import { GraphSource } from '@app/pages/graph/graph.cnst'
import { GraphService } from '@app/pages/graph/graph.service'
import { LangService } from '@app/srv/lang.service'
import { firstPageOpened } from '@app/srv/milestones'
import { dispatch, getState, StoreService } from '@app/srv/store.service'
import { logUtil } from '@app/util/log.util'
import { urlUtil } from '@app/util/url.util'
import { AppLauncher, OpenURLResult } from '@capacitor/app-launcher'
import { NavController } from '@ionic/angular'
import { AnimationBuilder } from '@ionic/core'
import { _last, localDate, StringMap } from '@naturalcycles/js-lib'
import { env } from '@src/environments/environment'
import { NativeSettings } from '@src/typings/capacitor'
import { filter, map, take } from 'rxjs/operators'
import { EVENT } from '../analytics/analytics.cnst'
import { ONBOARDING_PAGES_BY_ROUTE } from '../pages/flow/onboarding/onboarding.config'
import { PREGNANCY_END_PAGES_BY_ROUTE } from '../pages/flow/pregnancy-end/pregnancy-end.config'
import { UNPLANNED_PREGNANCY_PAGES_BY_ROUTE } from '../pages/flow/unplanned-pregnancy/unplanned-pregnancy.config'
import { di } from './di.service'
import { ExperimentService } from './experiment.service'

export interface NavOptions {
  flow?: string
  remove?: any[]
  [key: string]: any
}

export interface QS {
  refCode?: string
  refId?: string
}

let navService: NavService

export function addNavParams(params: StringMap<any>): void {
  navService.addNavParams(params)
}

export function removeNavParam(param: string): void {
  navService.removeNavParam(param)
}

export function getNavParams(): StringMap<any> {
  return navService.navParams
}

export function getNavigationState(): StringMap<any> {
  return di.get(Router).getCurrentNavigation()?.extras.state || {}
}

@Injectable({ providedIn: 'root' })
export class NavService {
  private storeService = inject(StoreService)
  private langService = inject(LangService)
  private router = inject(Router)
  private analyticsService = inject(AnalyticsService)
  private experimentService = inject(ExperimentService)
  private _navParams: StringMap<any> = {}

  constructor() {
    navService = this
  }

  public navigationStart$ = this.router.events.pipe(
    filter(event => event instanceof NavigationStart),
    map(event => (event as NavigationStart).url),
  )

  public navigationEnd$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(event => (event as NavigationEnd).url),
  )

  init(): void {
    this.router.onSameUrlNavigation = 'reload'

    this.navigationEnd$.pipe(take(1)).subscribe(url => firstPageOpened.resolve(url))
    this.navigationEnd$.subscribe(url => {
      void this.experimentService.logImpressionFromUrl(url)
    })

    if (isAndroidApp) {
      void NativeSettings.addListener('openInAppSettings', async () => {
        await firstPageOpened
        this.navigateForward(ROUTES.SettingsNotificationsPage)
      })
    }

    this.navigationEnd$.pipe(map(() => this.getCurrentComponent())).subscribe(page => {
      if (!page) return

      void this.analyticsService.trackView(page, { ...getNavParams(), ...getNavigationState() })
    })
  }

  public getCurrentRoute(): string {
    return this.router.url
  }

  public getCurrentComponent(): string | undefined {
    const { snapshot } = this.router.routerState

    // necessary for unit test, unless we mock the snapshot
    if (!snapshot) return

    const url = urlUtil.parseUrl(snapshot.url)

    const mappedRoute = analyticsRoutesMap[url.pathname]
    const route = Object.keys(ROUTES).find(key => ROUTES[key] === url.pathname)

    const componentName = mappedRoute || route || this.getParamRoute(url.pathname)

    if (!componentName && !env.prod) {
      logUtil.warn(`url missing in ROUTES: ${JSON.stringify(url)}`)
    }

    return componentName
  }

  private getParamRoute(url: string): string | undefined {
    if (url.startsWith(ROUTES.AddData)) return 'AddDataPage'
    if (url.startsWith(ROUTES.Guide)) return 'GuidePage'
    if (url.startsWith(ROUTES.Glossary)) return 'GlossaryItemPage'
    if (url.startsWith(ROUTES.TroubleshootingPage)) return 'ThermometerErrorItemPage'
    if (url.startsWith(ROUTES.ShopPage)) return 'ShopItemPage'
    if (url.startsWith(ROUTES.Cards)) return 'CardsPage'
    if (url.startsWith(ROUTES.ThermometerShipmentConfirmationPage)) {
      return 'ThermometerShipmentConfirmationPage'
    }
    if (url.startsWith(ROUTES.FreeThermometerInfoPage)) return 'FreeThermometerInfoPage'
    if (url.startsWith(ROUTES.BeforeYouSwitchPage)) return 'BeforeYouSwitchPage'
    if (url.startsWith(ROUTES.MultipleDeviceSwitchesPage)) return 'MultipleDeviceSwitchesPage'
    if (url.startsWith(ROUTES.HardwareDeviceInfoPage)) return 'HardwareDeviceInfoPage'

    if (url.startsWith(ROUTES.Onboarding)) return this.getOnboardingPageName(url)
    if (url.startsWith(ROUTES.PregnancyEnd)) return this.getPregnancyEndPageName(url)
    if (url.startsWith(ROUTES.UnplannedPregnancy)) return this.getUnplannedPregnancyPageName(url)
    return
  }

  private getOnboardingPageName(url: string): string | undefined {
    return ONBOARDING_PAGES_BY_ROUTE.find(item => item.key === url)?.value || _last(url.split('/'))
  }

  private getPregnancyEndPageName(url: string): string | undefined {
    return (
      PREGNANCY_END_PAGES_BY_ROUTE.find(item => item.key === url)?.value || _last(url.split('/'))
    )
  }

  private getUnplannedPregnancyPageName(url: string): string | undefined {
    return (
      UNPLANNED_PREGNANCY_PAGES_BY_ROUTE.find(item => item.key === url)?.value ||
      _last(url.split('/'))
    )
  }

  public redirectToWebSignup(qs: QS): boolean {
    if (qs.refCode) {
      const code = encodeURIComponent(qs.refCode)
      const referral = encodeURIComponent(qs.refId!)
      const url = `${env.websiteHost}/signup/secret?code=${code}&referral=${referral}`

      window.location.href = url
      return true
    }

    return false
  }

  async processInternalLink(path: string, source?: string, origin?: string): Promise<boolean> {
    await firstPageOpened

    // remove app.naturalcycles.com/
    if (path.startsWith('https://app.naturalcycles.com')) {
      path = path.split('app.naturalcycles.com')[1]!
    }

    logUtil.log('[dev] processInternalLink', path)

    const searchQ = path.split('?')[1]
    if (searchQ) {
      const params = urlUtil.getQueryString(searchQ) as QS

      const cleanParams = this.getCleanQSParams(params)

      // remove additional search params from path
      Object.keys(cleanParams).forEach(key => {
        const str = `&${key}=${cleanParams[key]}`
        path = path.replace(str, '')
      })

      addNavParams(cleanParams)
    }

    if (this.handleSpecialCases(path, origin)) return true

    // only handle links with same origin as local or app.naturalcycles.com from here on
    if (
      origin &&
      !(origin === window.location.origin || origin === 'https://app.naturalcycles.com')
    ) {
      return false
    }

    // safety check that the route exists
    // skip validation of routes with params
    if (
      !Object.values(ROUTES).includes(urlUtil.parseUrl(path).pathname) &&
      !this.getParamRoute(path)
    ) {
      return false
    }

    addNavParams({ [NavigationParams.SOURCE_PATH]: source })
    addAnalyticsProps({ source })
    this.navigateForward(path)

    return true
  }

  public navigateBack(link: string): void {
    const animation = this.getAnimation(link)

    void di
      .get(NavController)
      .navigateBack(link, { animation })
      .then(() => this.cleanParams())
  }

  public navigateForward(link: string): void {
    const animation = this.getAnimation(link)

    void di
      .get(NavController)
      .navigateForward(link, { animation })
      .then(() => this.cleanParams())
  }

  private handleSpecialCases(path: string, origin?: string): boolean {
    if (path.startsWith(ROUTES.GraphPage)) {
      void di.get(GraphService).openGraph(GraphSource.DEEPLINK, path)

      return true
    }

    if (origin === 'https://www.naturalcycles.com' && path === '/store') {
      if (!getState().userLocale.canDeliver) return false

      void this.navigateForward(ROUTES.ShopPage)

      return true
    }

    return false
  }

  private cleanParams(params?: string[]): void {
    const url = urlUtil.buildUrl(
      window.location.pathname,
      this.getCleanQSParams(undefined, params),
      true,
    )

    window.history.replaceState(undefined, '', url)
  }

  private getCleanQSParams(qs?: QS, _params?: string[]): { [k: string]: string } {
    const params = _params || [
      'tab',
      'supertab',
      'history',
      'modal',
      'hash',
      'guide',
      'sessionId',
      'sess',
      'mode',
      'name',
      'email',
      'token',
    ]
    const currentQS = qs || urlUtil.getLocationQueryString()
    const cleanQS: any = {}

    Object.keys(currentQS)
      .filter(name => !params.includes(name))
      .forEach(name => (cleanQS[name] = currentQS[name]))

    return cleanQS
  }

  public addNavParams(params: StringMap<any>): void {
    this._navParams = {
      ...this._navParams,
      ...params,
    }
  }

  public removeNavParam(param: string): void {
    delete this._navParams[param]
  }

  get navParams(): StringMap<any> {
    return this._navParams
  }

  private getAnimation(route: string): AnimationBuilder {
    const modalRoutes = [ROUTES.AddData, ROUTES.UebePairingPage, ROUTES.T3PairingPage]

    if (modalRoutes.includes(route)) return verticalSlideAnimation

    return horizontalSlideAnimation
  }

  public async openExternalApp(url: ExternalApp): Promise<OpenURLResult> {
    const canOpen = await AppLauncher.canOpenUrl({ url })

    if (!canOpen.value) {
      logUtil.error(`Can't open external app by url: ${url}`)
      this.analyticsService.trackEvent(EVENT.MISSING_EXTERNAL_APP, { url })
      return { completed: false }
    }

    const { completed } = await AppLauncher.openUrl({ url })
    this.analyticsService.trackEvent(EVENT.EXTERNAL_APP_OPENED, { url, completed })
    return { completed }
  }

  public async canOpenExternalApp(url: ExternalApp): Promise<boolean> {
    const { value } = await AppLauncher.canOpenUrl({ url })
    return value
  }

  public async openOuraApp(): Promise<void> {
    const isNative = window.Capacitor.isNativePlatform()
    if (!isNative) return

    await this.openExternalApp(isIOSApp ? ExternalApp.OURA : ExternalApp.ANDROID_OURA)
    dispatch('extendOura', { lastSyncClick: localDate.todayString() })
  }
}
