import { inject, Injectable } from '@angular/core'
import { api } from '@app/srv/api.service'
import { Device } from '@capacitor/device'
import { _deepEquals, _pick, localTime } from '@naturalcycles/js-lib'
import {
  BackendResponseFM,
  BackendResponseFMResp,
  UserDeviceFM,
  UserDeviceSaveInput,
} from '@naturalcycles/shared'
import { DeviceService } from '@src/app/srv/device.service'
import { MoEngageService } from '../analytics/moengage.service'
import { appVer } from '../cnst'
import { dispatch, getState } from './store.service'

const USER_DEVICE_FM_FIELDS: (keyof UserDeviceFM)[] = [
  'os',
  'manufacturer',
  'model',
  'version',
  'res',
  'deviceToken',
  'uuid',
  'utcOffset',
  'tz',
  'appVer',
]

// Called only once here, cause Intl.* APIs are considered slow-ish
// iOS 10+
// https://caniuse.com/mdn-javascript_builtins_intl_datetimeformat_resolvedoptions
const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

@Injectable({ providedIn: 'root' })
export class UserDeviceService {
  private deviceService = inject(DeviceService)
  private moEngageService = inject(MoEngageService)

  public async init(): Promise<UserDeviceFM> {
    // Init from bootstrap and update state with device data
    // UserDevice will be sent on postInit, session/auto
    const userDevice = await this.getUserDeviceData()

    // If there is no uuid in state and no uuid from device, create a new one
    userDevice.uuid ||= this.getRandomGuid()

    dispatch('setUserDevice', userDevice)

    return getState().userDevice
  }

  public async registerDeviceToken(deviceToken: string): Promise<UserDeviceFM> {
    const { userDevice: userDeviceInState, account, sessionId } = getState()

    const userDevice: UserDeviceFM = {
      ...(await this.getUserDeviceData()),
      deviceToken,
    }

    // Compare only meaningful fields, not e.g `accountId`, `updated`, `created`, etc.
    const isEqual = _deepEquals(
      _pick(userDevice, USER_DEVICE_FM_FIELDS),
      _pick(userDeviceInState, USER_DEVICE_FM_FIELDS),
    )

    if (!isEqual) {
      dispatch('setUserDevice', userDevice)

      // Only save if there's an Account and a Session (to prevent http 401)
      if (account.id && sessionId) {
        void this.save(userDevice)
      }
    }

    void this.moEngageService.registerDeviceToken(deviceToken)

    return getState().userDevice
  }

  async save(userDevice: UserDeviceSaveInput): Promise<void> {
    await api.put('userdevice', {
      json: userDevice,
    })
  }

  async isAuthorized(): Promise<BackendResponseFM> {
    const { identifier } = await Device.getId()
    const { userDeviceAuth } = getState()

    return await api
      .put<BackendResponseFMResp>('userdevice/isAuthorized', {
        json: {
          uuid: identifier,
          email: userDeviceAuth?.email || '',
        }, // satisfies CheckAuthorizationInput
      })
      .then(r => r.backendResponse)
  }

  private async getUserDeviceData(): Promise<UserDeviceFM> {
    const deviceInfo = await Device.getInfo()
    const { identifier } = await Device.getId()
    const state = getState()

    const os = this.deviceService.getOS()

    const userDevice: UserDeviceFM = {
      utcOffset: localTime.now().getUTCOffsetMinutes(),
      tz,
      appVer,
      os,
      uuid: identifier || state.userDevice.uuid,
      manufacturer: deviceInfo?.manufacturer,
      model: deviceInfo?.model,
      version: deviceInfo?.osVersion,
      res: [window.screen.width, window.screen.height].join('x'),
    }

    return userDevice
  }

  private getRandomGuid(): string {
    /* eslint-disable no-bitwise, unicorn/prefer-math-trunc */
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = (Math.random() * 16) | 0
      const v = c === 'x' ? r : r && 0x3 | 0x8
      return v.toString(16)
    })
  }
}
