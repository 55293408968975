import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { TemperatureC, TemperatureF } from '@app/cmp/temperature-numpad/temperature-numpad.service'
import { ROUTES } from '@app/cnst/nav.cnst'
import { isWebApp } from '@app/cnst/userDevice.cnst'
import { tryCatch } from '@app/decorators/tryCatch.decorator'
import { FreshBatteryModal } from '@app/modals/fresh-battery/fresh-battery.modal'
import { DateFormat, DateService } from '@app/srv/date.service'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { dispatch, getState, select2 } from '@app/srv/store.service'
import { TemperatureService } from '@app/srv/temperature.service'
import { tr } from '@app/srv/translation.util'
import { logUtil } from '@app/util/log.util'
import { PluginListenerHandle } from '@capacitor/core'
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem'
import { dataViewToNumbers, dataViewToText } from '@capacitor-community/bluetooth-le'
import { NavController } from '@ionic/angular'
import {
  _last,
  _omit,
  _round,
  _sortDescBy,
  _stringify,
  DeferredPromise,
  IsoDateString,
  localDate,
  localTime,
  pDefer,
  pDelay,
} from '@naturalcycles/js-lib'
import {
  AnalyticsEventType,
  BatteryStatus,
  HardwareDeviceTM,
  HardwareId,
  LANG,
  T3AlgorithmFlowPath,
} from '@naturalcycles/shared'
import { dayjs } from '@naturalcycles/time-lib'
import { env } from '@src/environments/environment'
import { NCHaptics, T3 } from '@src/typings/capacitor'
import {
  BehaviorSubject,
  filter,
  firstValueFrom,
  from,
  interval,
  mergeMap,
  skipWhile,
  takeUntil,
} from 'rxjs'
import { combineLatestWith, map, take } from 'rxjs/operators'
import { AnalyticsEventService } from '../analytics/analyticsEvent.service'
import { BluetoothService, SyncedTemperature } from './bluetooth.service'
import { HardwareDeviceService } from './hardwareDevice.service'
import { MarkdownService } from './markdown.service'
import { sentryService } from './sentry.service'
import {
  ACCOUNT_LANG_TO_T3_DDMM,
  ACCOUNT_LANG_TO_T3_LANG,
  FIXED_TEMPERATURE_T3_FW_VERSION,
  T3_BATTERY_LEVEL_TO_STATUS,
  T3_CHARACTERISTIC_UUID,
  T3_SCREEN_BRIGHTNESS_MODIFIER,
  T3_SERVICE_UUID,
  T3Algo,
  T3BatteryLevel,
  T3BrightnessConfig,
  T3Command,
  T3DeviceState,
  T3DeviceStatus,
  T3DiagnosticData,
  T3FWUpdateStatus,
  T3Lang,
  T3MeasurementState,
  T3MeasurementStatus,
  T3Screen,
  T3UIConfig,
  USERNAME_MAX_LENGTH,
} from './t3.cnst'

/* eslint-disable no-bitwise */

@Injectable({ providedIn: 'root' })
export class T3Service {
  private analyticsService = inject(AnalyticsService)
  private analyticsEventService = inject(AnalyticsEventService)
  private bluetoothService = inject(BluetoothService)
  private dateService = inject(DateService)
  private hardwareDeviceService = inject(HardwareDeviceService)
  private markdownService = inject(MarkdownService)
  private navController = inject(NavController)
  private popupController = inject(PopupController)
  private temperatureService = inject(TemperatureService)

  private latestFWVersion$ = select2(s => s.latestHWDeviceFWVersion)
  private hwDevice$ = select2(s => s.hwDevice)
  private failedFotaAttempts$ = select2(s => s.userSettings.failedFotaAttempts)

  public recentFailedFotaAttempts$ = this.failedFotaAttempts$.pipe(
    combineLatestWith(this.hwDevice$, this.latestFWVersion$),
    map(([failedFotaAttempts, hwDevice, latestFWVersion]) => {
      if (!hwDevice || !latestFWVersion) return []
      if (!failedFotaAttempts?.length) return []

      const today = localTime.now()
      // Choose only attempts for the current thermometer and the latest available firmware version in the last 30 days
      return failedFotaAttempts.filter(
        fa =>
          fa.mac === hwDevice.mac &&
          fa.fwVersion === latestFWVersion.version &&
          today.diff(localTime(fa.timestamp), 'day') < 30,
      )
    }),
  )

  private initialLogRead = false
  private listeners: PluginListenerHandle[] = []
  private temperatureLogs: SyncedTemperature[] = []
  private noTimestampLogs: (SyncedTemperature & { state: T3DeviceState })[] = []

  public FOTAUpdateStatus$ = new BehaviorSubject<T3FWUpdateStatus | undefined>(undefined)
  public FOTAProgress$ = new BehaviorSubject<number | undefined>(undefined)

  private resetFOTA?: DeferredPromise<string>

  constructor() {
    this.bluetoothService.connected$.pipe(filter(connected => !connected)).subscribe(() => {
      // We want to emit any unsaved values if thermometer suddenly disconnected
      this.emitTemperatureLogs()
    })
  }

  @tryCatch({
    alert: false,
    onError: error => {
      logUtil.error(error)
    },
  })
  public async getTemperatureLogs(deviceId: string, deviceStatus?: T3DeviceStatus): Promise<void> {
    deviceStatus ||= await this.getDeviceStatus(deviceId)
    const { deviceFlags, state } = deviceStatus

    if (!this.initialLogRead && deviceFlags % 2 === 0) {
      // odd value means "has unsynced temps", otherwise we have nothing to sync
      // emit empty array to acknowledge that there was a sync without temperatures
      this.bluetoothService.temperatures$.next([])

      return
    }

    const commandData: number[] = [0, 0, 0, 0, 0, 0, 0]

    const data = await this.bluetoothService.read(
      deviceId,
      T3_SERVICE_UUID.DEVICE,
      T3_CHARACTERISTIC_UUID.LOGS,
      'LOGS_CHARACTERISTIC',
    )

    const hasData = this.processLogs(data, state)

    await this.bluetoothService.write(
      deviceId,
      T3_SERVICE_UUID.DEVICE,
      T3_CHARACTERISTIC_UUID.LOGS,
      commandData,
      'GET_TEMPERATURE_LOGS',
    )

    await pDelay(100)

    // If there's no temperature data and this isn't the first response, all data have been synced
    if (!hasData && this.initialLogRead) {
      this.initialLogRead = false // reset until next logs reading

      // dont disconnect if logs state, user should turn off device first and then we'll be disconnected
      if (state === T3DeviceState.LOGS) return

      return await this.bluetoothService.disconnect(deviceId)
    }

    this.initialLogRead = true

    // Either it's the first read where response is always empty
    // Or we just read a temperature log, repeat the sequence to check if there's any more unsynced data
    await this.getTemperatureLogs(deviceId, deviceStatus)
  }

  public mockTemperatures(temperatures: { value?: number; date?: IsoDateString }[]): void {
    const {
      account: { fahrenheit },
      hwDevice,
    } = getState()
    const min = (fahrenheit ? TemperatureF.MIN : TemperatureC.MIN) * 100
    const max = (fahrenheit ? TemperatureF.MAX : TemperatureC.MAX) * 100
    const now = localTime.now()

    const syncedTemperatures: SyncedTemperature[] = temperatures.map(({ value, date }) => {
      if (value) {
        value *= 100
        value = Math.min(value, max)
        value = Math.max(value, min)
      }

      const temperature = (value ?? Math.floor(Math.random() * (max - min + 1) + min)) / 100

      const timestamp = localTime.orNow(date).setHour(now.hour).setMinute(now.minute).unix

      let successMsgId = Math.floor(Math.random() * 4) + 1
      switch (hwDevice?.batteryStatus) {
        case BatteryStatus.LOW:
          successMsgId = 5
          break
        case BatteryStatus.CRITICALLY_LOW:
          successMsgId = 6
          break
      }

      return { temperature, timestamp, successMsgId }
    })

    this.bluetoothService.temperatures$.next(syncedTemperatures)
  }

  private processLogs(data: DataView, state: T3DeviceState): boolean {
    const {
      account: { fahrenheit },
      hwDevice,
      userSettings: { firmwareUpdates },
    } = getState()

    /**
     * 0-3: time
     * 4-5: temperature
     * 6: algorithm
     * 7-8: wait for rise duration
     * 9-10: analysis duration
     * 11-12: prediction duration
     * 13-14: predicted temperature
     * 15: algorithm fail reason
     * 16: sync-success screen
     */
    const dataNumbers = dataViewToNumbers(data) as [
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
    ]
    const [
      time0,
      time1,
      time2,
      time3,
      temp0,
      temp1,
      algorithm,
      waitForRiseDuration0,
      waitForRiseDuration1,
      analysisDuration0,
      analysisDuration1,
      predictionDuration0,
      predictionDuration1,
      startingTemp0,
      startingTemp1,
      algorithmFlowPath,
      successMsgId,
    ] = dataNumbers

    // console.log('******* LOGS', {
    //   time0,
    //   time1,
    //   time2,
    //   time3,
    //   temp0,
    //   temp1,
    //   algorithm,
    //   waitForRiseDuration0,
    //   waitForRiseDuration1,
    //   analysisDuration0,
    //   analysisDuration1,
    //   predictionDuration0,
    //   predictionDuration1,
    //   startingTemp0,
    //   startingTemp1,
    //   algorithmFlowPath,
    //   successMsgId,
    // })

    // Once we get data where all numbers are zeroes, it means that T3 has no more values to sync
    // First log is also "all zeroes" but we skip it since logs array is empty
    if (this.temperatureLogs.length && dataNumbers.every(v => v === 0)) {
      this.emitTemperatureLogs()
    }

    const temperatureRaw = temp0 | (temp1 << 8)
    if (!temperatureRaw) {
      this.analyticsService.trackEvent(EVENT.NO_TEMPERATURE_LOG, {
        temperatureRaw,
      })

      return false
    }

    let temperature: number
    // Before 4.11.1 T3 returns value with 3 decimal digits and strange Clang rounding, so we apply fix
    if (hwDevice?.fwVersion && hwDevice.fwVersion < FIXED_TEMPERATURE_T3_FW_VERSION) {
      temperature = this.temperatureService.parseSyncedT3Temperature(temperatureRaw, !!fahrenheit)
    } else {
      temperature = this.temperatureService.parseSyncedTemperature(
        temperatureRaw / 10,
        !!fahrenheit,
      )
    }
    const temperatureRawCelsius = _round(temperatureRaw / 1000, 0.001)

    let timestamp = time0 | (time1 << 8) | (time2 << 16) | (time3 << 24)
    // We filter out by the same date in the backend. Thermometer started to sell in Feb 2023
    const hasNoTimestamp = timestamp < localDate('2023-01-01').unix

    let fwVersion: string | undefined
    const fwUpdates = firmwareUpdates?.filter(update => update.mac === hwDevice!.mac) ?? []
    // Sorting in reversed timeline to make it traverse easier
    _sortDescBy(fwUpdates, u => u.timestamp, true)

    if (!hasNoTimestamp) {
      // Adjust timestamp to correct value
      timestamp = this.dateService.t3TimestampToAppTimestamp(timestamp)

      // Use firmware version of the actual measurement
      if (fwUpdates?.length) {
        fwVersion = fwUpdates.find(update => update.timestamp <= timestamp)?.fwVersion
      }
    }
    fwVersion ??= hwDevice!.fwVersion

    const time = localTime(timestamp).toPretty()

    this.bluetoothService.log(
      `Temperature synced!
      Time: ${time},
      Temperature: ${temperature} °${fahrenheit ? 'F' : 'C'},
      Original temperature: ${temperatureRawCelsius} °C,
      Algorithm: ${T3Algo[algorithm]},
      Algo flow path: ${T3AlgorithmFlowPath[algorithmFlowPath]},
      Sync success screen ID: ${successMsgId}`,
    )

    // ignore logs without timestamp; should be fixed in the FW
    if (hasNoTimestamp) {
      this.analyticsService.trackEvent(EVENT.NO_TIMESTAMP_TEMPERATURE, {
        temperature,
        fahrenheit,
        temperatureRawCelsius,
        timestamp,
      })
      this.noTimestampLogs.push({
        temperature,
        timestamp,
        successMsgId,
        temperatureRawCelsius,
        state,
      })
      return true
    }

    this.temperatureLogs.push({
      temperature,
      timestamp,
      successMsgId,
      temperatureRawCelsius,
    })

    const waitForRiseDuration = waitForRiseDuration0 | (waitForRiseDuration1 << 8)
    const analysisDuration = analysisDuration0 | (analysisDuration1 << 8)
    const predictionDuration = predictionDuration0 | (predictionDuration1 << 8)
    const startingTemperatureRaw = startingTemp0 | (startingTemp1 << 8)
    const startingTemperature = _round(startingTemperatureRaw / 1000, 0.001)

    dispatch('extendT3Metadata', {
      date: localTime(timestamp).toISODate(),
      temperatureMeasuredTimestamp: timestamp,
      temperatureC: temperatureRawCelsius,
      waitForRiseDuration,
      analysisDuration,
      predictionDuration,
      startingTemperature,
      algorithmFlowPath,
      algorithm,
      fwVersion,
    })

    return true
  }

  private emitTemperatureLogs(): void {
    const noTimestampLogs = [...this.noTimestampLogs]
    this.noTimestampLogs = []

    if (!this.temperatureLogs.length) {
      // We want to sync NoTimestamp temperature as a regular one if following conditions are met:
      // 1. We have only one temperature and it has no timestamp
      // 2. Thermometer is in DONE or STANDBY state (so connected right after the measurement)
      // We will assign current timestamp and save it
      if (noTimestampLogs.length !== 1) return
      const noTimestampLog = noTimestampLogs[0]!
      if (![T3DeviceState.DONE, T3DeviceState.STANDBY].includes(noTimestampLog.state)) return
      noTimestampLog.timestamp = localTime.nowUnix()
      this.temperatureLogs = [_omit(noTimestampLog, ['state'])]
    }

    this.bluetoothService.temperatures$.next(this.temperatureLogs)
    void this.analyticsService.trackEvent(EVENT.BLUETOOTH_SYNCED, {
      temperatureCount: this.temperatureLogs.length,
    })
    this.temperatureLogs = []
  }

  public async getMeasurementStatus(deviceId: string): Promise<T3MeasurementStatus> {
    const data = await this.bluetoothService.read(
      deviceId,
      T3_SERVICE_UUID.DEVICE,
      T3_CHARACTERISTIC_UUID.MEASUREMENT_STATUS,
      'MEASUREMENT_STATUS_CHARACTERISTIC',
    )

    /**
     * 0: state
     * 1-2: latest temperature
     */
    const [state, temp0, temp1] = dataViewToNumbers(data) as [T3MeasurementState, number, number]

    return {
      state,
      temperature: (temp0 | (temp1 << 8)) / 1000,
    }
  }

  public async getDiagnosticData(deviceId: string): Promise<T3DiagnosticData> {
    const data = await this.bluetoothService.read(
      deviceId,
      T3_SERVICE_UUID.DEVICE,
      T3_CHARACTERISTIC_UUID.DIAGNOSTIC_DATA,
      'DIAGNOSTIC_DATA_CHARACTERISTIC',
    )

    /**
     * 0-1: Successful power on counter
     * 2-3: RTC sync counter
     * 4-5: User config changed counter
     * 6-7: Predictive measurement success counter
     * 8-9: MaxHold measurement success counter
     * 10-11: Measurement failed counter(Too low)
     * 12-13: Measurement failed counter(Too high)
     * 14-15: FW error counter
     * 16-17: HW error counter
     * 18-19: Calibration offset
     * 20-21: Calibration gain * 30’000
     * 22: NTC group
     * 23: Algorithm ID
     * 24-25: 4.12.0+ Latest wake up voltage
     * 26-27: 4.12.0+ Average battery voltage
     * 28-49: RFU - ignored
     */
    const [
      powerOn0,
      powerOn1,
      rtcSync0,
      rtcSync1,
      userConfig0,
      userConfig1,
      predMeasurement0,
      predMeasurement1,
      maxHoldMeasurement0,
      maxHoldMeasurement1,
      tooLowMeasurement0,
      tooLowMeasurement1,
      tooHighMeasurement0,
      tooHighMeasurement1,
      fwError0,
      fwError1,
      hwError0,
      hwError1,
      calibrationOffset0,
      calibrationOffset1,
      calibrationGain0,
      calibrationGain1,
      ntcGroup,
      algorithmId,
      latestWakeUpVoltage0,
      latestWakeUpVoltage1,
      averageBatteryVoltage0,
      averageBatteryVoltage1,
    ] = dataViewToNumbers(data) as [
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
      number,
    ]

    // undefined to not get spammed by `0` from the previous FW versions
    const latestWakeUpVoltage = latestWakeUpVoltage0 | (latestWakeUpVoltage1 << 8) || undefined
    const averageBatteryVoltage =
      averageBatteryVoltage0 | (averageBatteryVoltage1 << 8) || undefined

    return {
      powerOn: powerOn0 | (powerOn1 << 8),
      rtcSync: rtcSync0 | (rtcSync1 << 8),
      userConfig: userConfig0 | (userConfig1 << 8),
      predMeasurement: predMeasurement0 | (predMeasurement1 << 8),
      maxHoldMeasurement: maxHoldMeasurement0 | (maxHoldMeasurement1 << 8),
      tooLowMeasurement: tooLowMeasurement0 | (tooLowMeasurement1 << 8),
      tooHighMeasurement: tooHighMeasurement0 | (tooHighMeasurement1 << 8),
      fwError: fwError0 | (fwError1 << 8),
      hwError: hwError0 | (hwError1 << 8),
      calibrationOffset: calibrationOffset0 | (calibrationOffset1 << 8),
      calibrationGain: calibrationGain0 | (calibrationGain1 << 8),
      ntcGroup,
      algorithmId,
      latestWakeUpVoltage,
      averageBatteryVoltage,
    }
  }

  public async getSerialNumber(deviceId: string): Promise<string> {
    const data = await this.bluetoothService.read(
      deviceId,
      T3_SERVICE_UUID.DEVICE,
      T3_CHARACTERISTIC_UUID.SERIAL_NUMBER,
      'SERIAL_NUMBER_CHARACTERISTIC',
    )

    const serialNumber = String.fromCodePoint(...dataViewToNumbers(data).filter(Boolean))

    return serialNumber
  }

  public async getDeviceStatus(deviceId: string): Promise<T3DeviceStatus> {
    const data = await this.bluetoothService.read(
      deviceId,
      T3_SERVICE_UUID.DEVICE,
      T3_CHARACTERISTIC_UUID.DEVICE_STATUS,
      'DEVICE_STATUS_CHARACTERISTIC',
    )

    /**
     * 0-3: time
     * 4: state
     * 5-6: battery voltage
     * 7: device flags
     * 8: battery level
     */
    const [
      time0,
      time1,
      time2,
      time3,
      state,
      batteryVoltage0,
      batteryVoltage1,
      deviceFlags,
      batteryLevel,
    ] = dataViewToNumbers(data) as [
      number,
      number,
      number,
      number,
      T3DeviceState,
      number,
      number,
      number,
      T3BatteryLevel,
    ]

    const time = time0 | (time1 << 8) | (time2 << 16) | (time3 << 24)
    const batteryVoltage = Math.round(((batteryVoltage0 | (batteryVoltage1 << 8)) / 256) * 10) / 10

    const status: T3DeviceStatus = {
      time,
      state,
      batteryVoltage,
      batteryLevel,
      deviceFlags,
    }

    const timeSetting = dayjs
      .unix(this.dateService.t3TimestampToAppTimestamp(time))
      .utc()
      .toPretty(true)
    const diagnosticData = await this.getDiagnosticData(deviceId)

    this.analyticsService.trackEvent(EVENT.BLUETOOTH_DEVICE_STATE, {
      ..._omit(status, ['time']), // Mixpanel sets its own `time`
      description: T3DeviceState[state],
      timeSetting, // Passed as UTC but will be converted by Mixpanel
      ...diagnosticData,
    })

    this.hardwareDeviceService.saveBatteryStatus(T3_BATTERY_LEVEL_TO_STATUS[batteryLevel])

    await this.getFirmwareVersion(deviceId)

    await this.syncSettings(deviceId, status)

    return status
  }

  private async syncSettings(deviceId: string, status: T3DeviceStatus): Promise<void> {
    // set time if diff is more than one minute
    const t3Time = this.dateService.t3TimestampToAppTimestamp(status.time)
    if (Math.abs(localTime.nowUnix() - t3Time) > 60) {
      await this.setTime(deviceId)

      status.time = localTime.nowUnix()
    }

    const uiConfig = await this.getUIConfig(deviceId)
    const {
      account: { fahrenheit },
      hwDevice,
    } = getState()

    if (!!uiConfig.fahrenheit !== !!fahrenheit) {
      await this.setTempUnits(deviceId, !!fahrenheit)
    }

    // TODO temporary code to force updating screen brightness with accounting for modifier
    const screenBrightness = hwDevice?.screenBrightness ?? 75
    const deviceBrightness = (await this.getBrightnessConfig(deviceId)).screen
    if (
      Math.abs(deviceBrightness - Math.round(screenBrightness * T3_SCREEN_BRIGHTNESS_MODIFIER)) < 2
    ) {
      return
    }
    await this.setScreenBrightness(deviceId, screenBrightness)
  }

  public async getFirmwareVersion(deviceId: string): Promise<string> {
    const data = await this.bluetoothService.read(
      deviceId,
      T3_SERVICE_UUID.DEVICE_INFORMATION,
      T3_CHARACTERISTIC_UUID.FIRMWARE_REVISION,
      'FIRMWARE_CHARACTERISTIC',
    )

    const fwVersion = dataViewToText(data)
    this.bluetoothService.log(`Firmware version: ${fwVersion}`)

    await this.hardwareDeviceService.extendHardwareDevice({ fwVersion })

    return fwVersion
  }

  public async setUsername(deviceId: string, name: string): Promise<void> {
    let characters = name.split('').map(char => char.codePointAt(0)!)

    // need to truncate if too long
    if (characters.length > USERNAME_MAX_LENGTH) {
      characters = characters.slice(0, USERNAME_MAX_LENGTH - 3)
      characters.push(46, 46, 46) // ...
    }

    // save new username
    await this.bluetoothService.write(
      deviceId,
      T3_SERVICE_UUID.USER_CONFIG,
      T3_CHARACTERISTIC_UUID.USER_NAME,
      [...characters, 0],
      'SET_USERNAME',
    )
  }

  public async setBacklight(deviceId: string, backlight: boolean): Promise<void> {
    await this.setUIConfig(deviceId, { backlight })
  }

  public async setSound(deviceId: string, sound: boolean): Promise<void> {
    await this.setUIConfig(deviceId, { sound })
  }

  public async setDateFormat(deviceId: string, language: LANG): Promise<void> {
    await this.setUIConfig(deviceId, { ddMM: ACCOUNT_LANG_TO_T3_DDMM[language] })
  }

  public async setTempUnits(deviceId: string, fahrenheit: boolean): Promise<void> {
    await this.setUIConfig(deviceId, { fahrenheit })
  }

  public async setLanguage(deviceId: string, language: LANG): Promise<void> {
    await this.setUIConfig(deviceId, { language: ACCOUNT_LANG_TO_T3_LANG[language] })
  }

  public async setLeftHandMode(deviceId: string, leftHandMode: boolean): Promise<void> {
    await this.setUIConfig(deviceId, { leftHandMode })
  }

  public async setNfcAvailability(deviceId: string, available: boolean): Promise<void> {
    const commandData: number[] = [T3Command.SET_NFC_AVAILABILITY, available ? 1 : 0, 0, 0, 0]

    await this.bluetoothService.write(
      deviceId,
      T3_SERVICE_UUID.DEVICE,
      T3_CHARACTERISTIC_UUID.COMMANDS,
      commandData,
      T3Command[T3Command.SET_NFC_AVAILABILITY],
    )
  }

  public async factoryReset(deviceId: string): Promise<void> {
    // change screen
    await this.setScreen(deviceId, T3Screen.FACTORY_RESET)

    const alert = await this.popupController.presentAlert(
      {
        header: tr('msg-factoryReset-areYouSure'),
        message: this.markdownService.parse(tr('alert-factory-reset-body')),
        buttons: [
          {
            text: tr('txt-reset-thermometer'),
            role: 'reset',
          },
          {
            text: tr('btn-cancel'),
            role: 'cancel',
          },
        ],
      },
      'alert-t3FactoryReset',
      Priority.IMMEDIATE,
    )

    const { role } = await alert.onDidDismiss()
    if (role === 'reset') {
      this.analyticsService.trackEvent(EVENT.THERMOMETER_FACTORY_RESET, { deviceId })

      const commandData: number[] = [T3Command.FACTORY_RESET, 0, 0, 0, 0]

      await this.bluetoothService.write(
        deviceId,
        T3_SERVICE_UUID.DEVICE,
        T3_CHARACTERISTIC_UUID.COMMANDS,
        commandData,
        T3Command[T3Command.FACTORY_RESET],
      )
      await pDelay(1500)
      await this.navController.navigateForward(ROUTES.T3PairingPage)
      await this.hardwareDeviceService.clearHardwareDevice(deviceId, true)
    }
  }

  public async setScreenBrightness(deviceId: string, percent: number): Promise<void> {
    const screen = Math.round(((percent * T3_SCREEN_BRIGHTNESS_MODIFIER) / 100) * 255)

    await this.bluetoothService.write(
      deviceId,
      T3_SERVICE_UUID.USER_CONFIG,
      T3_CHARACTERISTIC_UUID.BRIGHTNESS,
      [screen, 100, 0, 0], // set power button to 100; settings- & logsButton to 0
      'SET_SCREEN_BRIGHTNESS',
    )
  }

  public async getBrightnessConfig(deviceId: string): Promise<T3BrightnessConfig> {
    const data = await this.bluetoothService.read(
      deviceId,
      T3_SERVICE_UUID.USER_CONFIG,
      T3_CHARACTERISTIC_UUID.BRIGHTNESS,
      'BRIGHTNESS_CHARACTERISTIC',
    )

    /**
     * Screen: 0-255
     * The rest: 0-100
     */
    const [screen, onOffButton, settingsButton, logsButton] = dataViewToNumbers(data)

    return {
      screen: ((screen || 0) / 255) * 100,
      onOffButton: onOffButton || 0,
      settingsButton: settingsButton || 0,
      logsButton: logsButton || 0,
    }
  }

  public async setAlgo(deviceId: string, algo: T3Algo): Promise<void> {
    const commandData: number[] = [T3Command.SET_ALGO, algo, 0, 0, 0]

    await this.bluetoothService.write(
      deviceId,
      T3_SERVICE_UUID.DEVICE,
      T3_CHARACTERISTIC_UUID.COMMANDS,
      commandData,
      T3Command[T3Command.SET_ALGO],
    )
  }

  public async setTime(deviceId: string): Promise<void> {
    const localTimestamp = this.dateService.appTimestampToT3Timestamp()

    const commandData: number[] = [
      T3Command.SET_TIME,
      localTimestamp & 255,
      (localTimestamp >> 8) & 255,
      (localTimestamp >> 16) & 255,
      (localTimestamp >> 24) & 255,
    ]

    await this.bluetoothService.write(
      deviceId,
      T3_SERVICE_UUID.DEVICE,
      T3_CHARACTERISTIC_UUID.COMMANDS,
      commandData,
      T3Command[T3Command.SET_TIME],
    )
  }

  public async setAllSettings(settings: HardwareDeviceTM): Promise<void> {
    const { mac: id, buzzer, backlight, nfcMode, screenBrightness, leftHandMode } = settings
    const { fahrenheit, lang, name1 } = getState().account

    if (name1) await this.setUsername(id, name1)

    await this.setLanguage(id, lang as LANG)

    await this.setDateFormat(id, lang as LANG)

    await this.setBacklight(id, backlight)

    await this.setSound(id, buzzer)

    await this.setNfcAvailability(id, !!nfcMode)

    await this.setScreenBrightness(id, screenBrightness || 75)

    await this.setLeftHandMode(id, !!leftHandMode)

    await this.setAlgo(id, T3Algo.PREDICTIVE)

    await this.setTempUnits(id, !!fahrenheit)

    await this.setTime(id)
  }

  public async setScreen(deviceId: string, screen: T3Screen): Promise<void> {
    const commandData: number[] = [T3Command.SET_SCREEN, screen, 0, 0, 0]

    await this.bluetoothService.write(
      deviceId,
      T3_SERVICE_UUID.DEVICE,
      T3_CHARACTERISTIC_UUID.COMMANDS,
      commandData,
      T3Command[T3Command.SET_SCREEN],
    )
  }

  private async setUIConfig(
    deviceId: string,
    { backlight, sound, ddMM, fahrenheit, leftHandMode, language }: T3UIConfig,
  ): Promise<void> {
    const {
      account: { fahrenheit: _fahrenheit, lang },
      hwDevice,
    } = getState()
    const today = dayjs()

    backlight ??= hwDevice?.backlight
    sound ??= hwDevice?.buzzer

    ddMM ??= this.dateService
      .localizeDate(today, DateFormat.DAY_MONTH)
      .startsWith(today.date().toString())

    fahrenheit ??= _fahrenheit
    language ??= ACCOUNT_LANG_TO_T3_LANG[lang as LANG]

    let config = backlight ? 1 : 0
    if (sound) config += 2
    if (ddMM) config += 4
    if (fahrenheit) config += 8
    if (leftHandMode) config += 16

    const configData: number[] = [config, language || T3Lang.EN]

    await this.bluetoothService.write(
      deviceId,
      T3_SERVICE_UUID.USER_CONFIG,
      T3_CHARACTERISTIC_UUID.UI_CONFIG,
      configData,
      'SET_UI_CONFIG',
    )
  }

  private async getUIConfig(deviceId: string): Promise<T3UIConfig> {
    const data = await this.bluetoothService.read(
      deviceId,
      T3_SERVICE_UUID.USER_CONFIG,
      T3_CHARACTERISTIC_UUID.UI_CONFIG,
      'UI_CONFIG_CHARACTERISTIC',
    )
    const [settings, language] = dataViewToNumbers(data)

    const [backlight, sound, ddMM, fahrenheit, leftHandMode] = settings!
      .toString(2)
      .split('')
      .map(n => !!Number(n))

    return {
      backlight,
      sound,
      ddMM,
      fahrenheit,
      leftHandMode,
      language,
    }
  }

  private async setToFOTAState(): Promise<boolean> {
    const data = [1, 0, 0, 0]
    await T3.sendCommand({ command: T3Command.FOTA, data }).catch(err =>
      this.logFOTAPluginError('T3.sendCommand', err),
    )

    // todo: use pTimeout instead
    return await Promise.race([
      pDelay(30_000, false),
      // biome-ignore lint/suspicious/noAsyncPromiseExecutor: ok
      new Promise<boolean>(async resolve => {
        let deviceStatus: any
        do {
          await pDelay(500)
          try {
            deviceStatus = await T3.getDeviceStatus()
          } catch (err: unknown) {
            this.logFOTAPluginError('T3.getDeviceStatus', err)
          }
          this.bluetoothService.log(`deviceStatus: ${_stringify(deviceStatus)}`)
        } while (
          deviceStatus &&
          deviceStatus.state !== T3DeviceState.FOTA &&
          this.FOTAUpdateStatus$.value === T3FWUpdateStatus.STARTING
        )
        resolve(true)
      }),
    ])
  }

  private async connectInFOTAMode(deviceId: string): Promise<boolean> {
    // If we can get deviceStatus then thermometer is still connected
    const deviceStatus = await T3.getDeviceStatus().catch(err => {
      this.bluetoothService.log(`FOTA can't get device status in connectInFOTAMode`)
      this.logFOTAPluginError('T3.getDeviceStatus', err, true)
    })

    if (deviceStatus && [T3DeviceState.HOME, T3DeviceState.SETTINGS].includes(deviceStatus.state)) {
      return true
    }
    // Otherwise, try to re-connect

    await T3.initialize().catch(err => this.logFOTAPluginError('T3.initialize', err))

    try {
      await T3.scan().catch(err => this.logFOTAPluginError('T3.scan', err))

      const pairedDeviceFound = await firstValueFrom(
        interval(500).pipe(
          take(90),
          mergeMap(async () => {
            let devices: any[] = []
            try {
              devices = (await T3.getDevices()).devices
            } catch (err: unknown) {
              this.logFOTAPluginError('T3.getDevices', err)
            }
            return devices.includes(deviceId)
          }),
          skipWhile(res => !res),
          takeUntil(from(this.resetFOTA!)),
        ),
      ).catch(() => false) // Catching EmptyError: no elements in sequence

      if (!pairedDeviceFound) return false

      await T3.stopScan().catch(err => this.logFOTAPluginError('T3.stopScan', err))
      await T3.connect({ toDevice: deviceId }).catch(err =>
        this.logFOTAPluginError('T3.connect', err),
      )

      const connectedInFOTAMode = await firstValueFrom(
        interval(500).pipe(
          take(90),
          mergeMap(async () => {
            // biome-ignore lint: ok
            let deviceStatus
            try {
              deviceStatus = await T3.getDeviceStatus()
            } catch (err: unknown) {
              this.logFOTAPluginError('T3.getDeviceStatus', err)
            }

            return (
              !!deviceStatus &&
              [T3DeviceState.HOME, T3DeviceState.SETTINGS].includes(deviceStatus.state)
            )
          }),
          skipWhile(res => !res),
          takeUntil(from(this.resetFOTA!)),
        ),
      ).catch(() => false)

      return connectedInFOTAMode
    } catch (err) {
      this.addBluetoothLogsToSentryBreadcrumbs()
      logUtil.error(`connectInFOTAMode error: ${err}`)
      // Let's retry if there is an error during scanning or connection
      return await this.connectInFOTAMode(deviceId)
    }
  }

  private async initFOTAListeners(deviceId: string): Promise<void> {
    this.listeners.push(
      await T3.addListener('fotaDidProgress', async ({ progress }) => {
        this.FOTAProgress$.next(progress)
        if (progress === 1 || progress % 10 === 0) {
          this.analyticsService.trackEvent(EVENT.FIRMWARE_UPDATE_FOTA_PROGRESS, { progress })
        }
      }),
      await T3.addListener('fotaDidSucceed', () => this.onFOTADidSucceed(deviceId)),
      await T3.addListener('fotaDidFail', () => this.onFOTADidFail(deviceId)),
    )
  }

  private async ensureFWBinaryExists(version: string, filename: string): Promise<boolean> {
    try {
      await Filesystem.readFile({
        path: `fw/${filename}.bin`,
        directory: Directory.Data,
        encoding: Encoding.UTF8,
      })
      return true
    } catch {
      void this.analyticsService.trackEvent(EVENT.FW_BINARY_UNAVAILABLE)
    }

    return await this.hardwareDeviceService.downloadFWBinary(version)
  }

  public async performFOTA(deviceId: string): Promise<void> {
    this.resetFOTA = pDefer()

    const { version } = getState().latestHWDeviceFWVersion!
    const filename = this.hardwareDeviceService.getFWFilename(version)

    if (!(await this.ensureFWBinaryExists(version, filename))) {
      return this.updateFOTAStatus(T3FWUpdateStatus.FAILED, 'Firmware binary unavailable')
    }

    // If thermometer is connected using BleClient, force to disconnect
    const connectedBluetoothDevice = this.bluetoothService.connected$.value
    if (connectedBluetoothDevice) {
      await this.bluetoothService.disconnect(connectedBluetoothDevice)
    }

    this.updateFOTAStatus(
      T3FWUpdateStatus.DISCONNECTED,
      'Thermometer disconnected from BleClient, waiting for FOTA mode',
    )

    await this.bluetoothService.ensureBluetoothEnabled(true)

    this.listeners.push(
      await T3.addListener('log', ({ key, msg }) => {
        if (key === 'fwFileSize') {
          this.analyticsService.trackEvent(EVENT.FW_FILE_SIZE, { version, fileSize: msg })
        }

        if (env.prod) return
        this.bluetoothService.log(msg)
      }),
    )

    const isConnected = await this.connectInFOTAMode(deviceId)

    if (!isConnected) {
      this.bluetoothService.log(`FOTA thermometer didn't connect in FOTA mode`)
      return this.updateFOTAStatus(
        T3FWUpdateStatus.FAILED,
        "Thermometer didn't connect in FOTA mode",
      )
    }

    this.updateFOTAStatus(T3FWUpdateStatus.STARTING)

    if (!(await this.setToFOTAState())) {
      this.bluetoothService.log('FOTA not initialized')
      this.analyticsService.trackEvent(EVENT.FIRMWARE_UPDATE_FOTA_INIT, {
        success: false,
        version,
      })
      this.logFailedFotaAttempt(deviceId)
      return this.updateFOTAStatus(T3FWUpdateStatus.FAILED, 'Failed to set into FOTA state')
    }

    this.analyticsService.trackEvent(EVENT.FIRMWARE_UPDATE_FOTA_INIT, {
      success: true,
      version,
    })

    this.bluetoothService.log('FOTA initialized: proceeding to performFOTA')

    await this.initFOTAListeners(deviceId)

    this.updateFOTAStatus(T3FWUpdateStatus.IN_PROGRESS)
    this.FOTAProgress$.next(0)

    await T3.performFOTA({ filename }).catch(err => this.logFOTAPluginError('T3.performFOTA', err))
  }

  public async cancelFOTA(
    status: T3FWUpdateStatus | undefined,
    description: string,
  ): Promise<void> {
    this.updateFOTAStatus(status, description)

    const data = [2, 0, 0, 0]
    await T3.sendCommand({ command: T3Command.FOTA, data }).catch(err =>
      this.logFOTAPluginError('T3.sendCommand', err),
    )

    const {
      latestHWDeviceFWVersion,
      userSettings: { failedFotaAttempts },
    } = getState()
    const { version } = latestHWDeviceFWVersion!

    if (status !== T3FWUpdateStatus.COMPLETED) {
      this.trackFotaUpdateDone({
        result: `FOTA was cancelled because: ${description}`,
        success: false,
        failedFotaAttempts,
        version,
      })
    }

    this.reset()
  }

  public async executeFWUpdate(deviceId: string): Promise<void> {
    const data = [3, 0, 0, 0]
    const { version } = getState().latestHWDeviceFWVersion!

    try {
      await T3.sendCommand({ command: T3Command.FOTA, data })
    } catch (err) {
      this.logFOTAPluginError('T3.sendCommand', err)
      this.logFailedFotaAttempt(deviceId)
      this.updateFOTAStatus(T3FWUpdateStatus.FAILED, 'Failed to execute FW update')

      const { failedFotaAttempts } = getState().userSettings
      this.bluetoothService.log(`FOTA failed ${_stringify(err)}`)
      this.trackFotaUpdateDone({
        result: err,
        success: false,
        failedFotaAttempts,
        version,
      })

      return this.reset()
    }

    const { failedFotaAttempts } = getState().userSettings
    this.bluetoothService.log('FOTA success')
    this.trackFotaUpdateDone({
      success: true,
      failedFotaAttempts,
      version,
    })

    if (!isWebApp) void NCHaptics.impact()

    const hwDeviceStash = this.hardwareDeviceService.hwDeviceStash$.value
    if (!hwDeviceStash) return

    await this.hardwareDeviceService.saveHardwareDevice(hwDeviceStash, HardwareId.T3_THERMOMETER)
    this.hardwareDeviceService.hwDeviceStash$.next(null)
  }

  public async finishFWUpdate(deviceId: string, isRetry?: boolean): Promise<void> {
    this.updateFOTAStatus(T3FWUpdateStatus.WAITING_TO_SYNC)

    void this.bluetoothService.scanAndConnect(deviceId)

    const pairedDeviceFound = await firstValueFrom(
      interval(500).pipe(
        take(90),
        mergeMap(async () => this.bluetoothService.connected$.value),
        skipWhile(connected => !connected),
        takeUntil(from(this.resetFOTA!)),
      ),
    ).catch(() => false) // Catching EmptyError: no elements in sequence

    if (!pairedDeviceFound) {
      if (!isRetry) return this.updateFOTAStatus(T3FWUpdateStatus.SYNC_FAILED)

      this.logFailedFotaAttempt(deviceId)
      this.updateFOTAStatus(
        T3FWUpdateStatus.FAILED,
        'firmware update finished but failed to reconnect thermometer',
      )
      this.reset()
      return
    }

    await this.getDeviceStatus(deviceId)

    const { latestHWDeviceFWVersion } = getState()
    const fwVersion = await this.getFirmwareVersion(deviceId)
    if (fwVersion !== latestHWDeviceFWVersion?.version) {
      this.logFailedFotaAttempt(deviceId)
      this.updateFOTAStatus(
        T3FWUpdateStatus.FAILED,
        'Firmware update finished but thermometer reported incorrect firmware version',
      )
      this.reset()
      return
    }

    this.updateFOTAStatus(T3FWUpdateStatus.COMPLETED)

    this.reset()
  }

  private reset(): void {
    this.listeners.forEach(listener => listener.remove())
    this.listeners.length = 0
    this.resetFOTA?.resolve('reset')
    this.FOTAProgress$.next(undefined)
    this.bluetoothService.log('FOTA reset')
  }

  private async onFOTADidSucceed(deviceId: string): Promise<void> {
    const { version } = getState().latestHWDeviceFWVersion!

    this.bluetoothService.log('fotaDidSucceed')
    this.analyticsService.trackEvent(EVENT.FIRMWARE_UPDATE_FOTA, {
      success: true,
      version,
    })

    const fotaSucceeded = await Promise.race([
      pDelay(30_000, false),
      // biome-ignore lint/suspicious/noAsyncPromiseExecutor: ok
      new Promise<boolean>(async resolve => {
        // biome-ignore lint: ok
        let deviceStatus
        do {
          await pDelay(500)
          // biome-ignore lint: ok
          let _deviceStatus
          try {
            _deviceStatus = await T3.getDeviceStatus()
          } catch (err: unknown) {
            this.logFOTAPluginError('T3.getDeviceStatus', err)
          }
          this.bluetoothService.log(`fotaDidSucceed status ${_stringify(_deviceStatus)}`)
          if (_deviceStatus && deviceStatus?.state !== _deviceStatus.state) {
            this.analyticsService.trackEvent(EVENT.FIRMWARE_UPDATE_THERM_STATUS, {
              success: true,
              result: { ...(deviceStatus = _deviceStatus) },
              version,
            })
          }
        } while (
          deviceStatus?.state !== T3DeviceState.FOTA_SUCCEED &&
          this.FOTAUpdateStatus$.value === T3FWUpdateStatus.IN_PROGRESS
        )
        resolve(true)
      }),
    ])

    this.analyticsService.trackEvent(EVENT.FOTA_SUCCEEDED, {
      success: fotaSucceeded,
      version,
    })

    if (fotaSucceeded) {
      this.updateFOTAStatus(T3FWUpdateStatus.SUCCEEDED)
    } else {
      this.logFailedFotaAttempt(deviceId)
      this.updateFOTAStatus(T3FWUpdateStatus.FAILED, 'FOTA finished but failed to confirm success')
      this.bluetoothService.log('Device state not FOTA_SUCCEED in fotaDidSucceed')
      this.reset()
      return
    }

    // Plugin reports FOTA progress as 0..99. We want to show 100% to the user when we reach SUCCEEDED status
    this.FOTAProgress$.next(100)

    await pDelay(500)
    await this.executeFWUpdate(deviceId)
  }

  private async onFOTADidFail(deviceId: string): Promise<void> {
    const { version } = getState().latestHWDeviceFWVersion!

    this.bluetoothService.log('onFOTADidFail')
    this.analyticsService.trackEvent(EVENT.FIRMWARE_UPDATE_FOTA, {
      success: false,
      version,
    })

    try {
      const deviceStatus = await T3.getDeviceStatus()
      this.bluetoothService.log(`onFOTADidFail status ${_stringify(deviceStatus)}`)
    } catch (err) {
      this.logFOTAPluginError('T3.getDeviceStatus', err)
      this.addBluetoothLogsToSentryBreadcrumbs()
      this.bluetoothService.log(`onFOTADidFail error ${err}`)
      logUtil.error(`onFOTADidFail error: ${err}`)
    }

    this.logFailedFotaAttempt(deviceId)
    await this.cancelFOTA(T3FWUpdateStatus.FAILED, 'T3 plugin reported FOTA failure')
  }

  public resetFOTAStatus(description?: string): void {
    this.updateFOTAStatus(undefined, description)
  }

  private updateFOTAStatus(status: T3FWUpdateStatus | undefined, description?: string): void {
    if (this.FOTAUpdateStatus$.value === status) return

    const { version } = getState().latestHWDeviceFWVersion!
    const progress = this.FOTAProgress$.value
    this.FOTAUpdateStatus$.next(status)
    this.bluetoothService.firmwareUpdateOngoing$.next(
      (!!status && status < T3FWUpdateStatus.FAILED) || status === T3FWUpdateStatus.SUCCEEDED,
    )

    this.analyticsService.trackEvent(EVENT.FW_UPDATE_STATUS_CHANGED, {
      status: status ? T3FWUpdateStatus[status] : 'undefined',
      progress,
      description,
      version,
    })
  }

  private trackFotaUpdateDone<T extends { success: boolean }>(props: T): void {
    this.analyticsService.trackEvent(EVENT.FW_UPDATE_DONE, { ...props })
    void this.analyticsEventService.addEvent(
      props.success ? AnalyticsEventType.T3_FOTA_SUCCEEDED : AnalyticsEventType.T3_FOTA_FAILED,
    )
  }

  /**
   * Should be called only when we don't know if the fail was caused by the user
   */
  private logFailedFotaAttempt(mac: string): void {
    const { hwDevice, latestHWDeviceFWVersion } = getState()
    dispatch('extendFailedFotaAttempts', {
      hwId: HardwareId.T3_THERMOMETER,
      mac,
      previousVersion: hwDevice?.fwVersion,
      fwVersion: latestHWDeviceFWVersion?.version,
      timestamp: localTime.nowUnix(),
      success: false,
    })
  }

  public logFOTAPluginError(source: string, err: unknown, suppressError = false): void {
    this.bluetoothService.log(`FOTA plugin error. ${source} thrown`)
    this.bluetoothService.log(_stringify(err, { includeErrorStack: true, includeErrorData: true }))
    this.analyticsService.trackEvent(EVENT.FIRMWARE_UPDATE_FOTA_PLUGIN_ERROR, {
      source,
      error: _stringify(err),
    })
    this.addBluetoothLogsToSentryBreadcrumbs()
    logUtil.error(err)

    if (suppressError) return
    throw err
  }

  /**
   * Splits Bluetooth logs into smaller chunks and adds them to Sentry breadcrumbs
   * Required to account Sentry's 8192 characters message limit
   */
  private addBluetoothLogsToSentryBreadcrumbs(): void {
    const logs = this.bluetoothService.logs$.value
    if (!logs.length) return

    const sentryMessageCharLimit = 8192
    // eslint-disable-next-line unicorn/no-array-reduce
    const breadcrumbs = logs.reduce(
      (acc, item) => {
        const lastString = _last(acc)
        if (lastString.length + item.length + 2 < sentryMessageCharLimit) {
          acc[acc.length - 1] += (lastString ? '\n' : '') + item
        } else {
          acc.push(item)
        }
        return acc
      },
      [''],
    )

    // Reverse to log from top to bottom for better readability
    breadcrumbs.reverse().forEach(message => {
      sentryService.addBreadcrumb({
        type: 'debug',
        message,
      })
    })
  }

  public async promptFreshBattery(): Promise<boolean> {
    const modal = await this.popupController.presentModal(
      {
        component: FreshBatteryModal,
      },
      'modal-freshBattery',
      Priority.IMMEDIATE,
    )
    const { data } = await modal.onWillDismiss()

    return !!data
  }
}
