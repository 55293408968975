import { appVer } from '@app/cnst'
import { buildInfo } from '@app/util/buildInfo.util'
import { _filterFalsyValues, _stringify, StringMap } from '@naturalcycles/js-lib'
import { ExternalAccountId } from '@naturalcycles/shared'
import { BrowserOptions, init as sentryBrowserInit } from '@sentry/browser'
import {
  addBreadcrumb,
  Breadcrumb,
  CapacitorOptions,
  captureException,
  captureMessage,
  init as sentryNativeInit,
  setExtras,
  setTags,
  setUser,
  SeverityLevel,
} from '@sentry/capacitor'
import { CaptureContext } from '@sentry/types'
import { normalize } from '@sentry/utils'
import { env } from '@src/environments/environment'

// Important!
// Please try to avoid importing many services here (e.g StoreService),
// since it becomes vulnerable to circular dependencies

const { sentryDsn: dsn, name: environment, prod } = env

class SentryService {
  constructor() {
    if (env.test) return // no need to init Sentry in test
    ;(globalThis as any).sentryService = this // debug

    const opt: BrowserOptions = {
      dsn,
      environment,
      release: buildInfo.ver,
      maxValueLength: 2000, // default is 250 characters
      maxBreadcrumbs: 50, // to prevent "413 Request Entity Too Large" error
      beforeSend: (event, _hint) => {
        // todo: use sentryGroupingStrategy from js-lib as soon as it's ready
        // event.fingerprint = ['{{ default }}', _stringify(hint?.originalException)]

        return event
      },
      // todo: figure out how to read RemoteConfig.sentrySampleRate from here
      sampleRate: env.prod ? 0.2 : 1,
    }

    if (window.Capacitor.isNativePlatform()) {
      sentryNativeInit({
        ...opt,
        enableOutOfMemoryTracking: false,
      } satisfies CapacitorOptions)
    } else {
      sentryBrowserInit(opt)
    }

    this.setTags({
      appVer,
      buildInfo_ver: buildInfo.ver, // eslint-disable-line @typescript-eslint/naming-convention
      buildInfo_env: buildInfo.env, // eslint-disable-line @typescript-eslint/naming-convention
    })
  }

  // Returns lastEventId
  captureException(err: any, captureContext?: CaptureContext): string | undefined {
    if (err?.cause?.data?.userFriendly) return // ignore userFriendly errors

    const errorString = _stringify(err, { includeErrorData: true })

    if (prod) {
      sentryService.addBreadcrumb({
        message: errorString,
      })
    } else {
      console.error(errorString)
    }

    return captureException(err, captureContext)
  }

  // Returns lastEventId
  captureMessage(message: string, severity?: SeverityLevel): string {
    return captureMessage(message, severity)
  }

  addBreadcrumb(crumb: Breadcrumb): void {
    addBreadcrumb({
      ...crumb,
      data: crumb.data ? normalize(crumb.data) : undefined,
    })
  }

  setExtras(ctx: any): void {
    setExtras(ctx)
  }

  setUser(id: ExternalAccountId): void {
    setUser({ id })
  }

  setTags(tags: StringMap<string | number>): void {
    setTags(_filterFalsyValues(tags))
  }
}

export const sentryService = new SentryService()
