import { StringMap } from '@naturalcycles/js-lib'
import { Goal, HardwareId } from '@naturalcycles/shared'
import { ROUTES } from '../cnst/nav.cnst'

export enum TourTooltip {
  APP_1_1 = 'app-tour-step1-tooltip1',
  APP_1_2 = 'app-tour-step1-tooltip2',
  APP_2_1 = 'app-tour-step2-tooltip3',
  APP_2_2 = 'app-tour-step2-tooltip2',
  APP_2_3 = 'app-tour-step2-tooltip4',
  APP_2_4 = 'app-tour-step2-tooltip1',
  APP_3_1 = 'app-tour-step3-tooltip-insights',
  APP_4_1 = 'app-tour-step4-tooltip1',
  APP_5_1 = 'app-tour-step5-tooltip1',
  APP_5_2 = 'app-tour-step5-tooltip2',
  APP_6_1 = 'app-tour-step6-tooltip1',
  APP_6_2 = 'app-tour-step6-tooltip2',
  APP_6_3 = 'app-tour-plan-step6-tooltip3',
  APP_7_1 = 'app-tour-step7-tooltip1',
  APP_7_2 = 'app-tour-step7-tooltip2',

  DEMO_1_1 = 'demo-tour-step1-tooltip2',
  DEMO_1_2 = 'demo-tour-step1-tooltip3',
  DEMO_2_1 = 'demo-tour-step2-tooltip1',
  DEMO_2_2 = 'demo-tour-step2-tooltip2',
  DEMO_2_3 = 'demo-tour-step2-tooltip3',
  DEMO_3_1 = 'app-tour-step4-tooltip1', // eslint-disable-line @typescript-eslint/no-duplicate-enum-values
  DEMO_4_1 = 'app-tour-step5-tooltip1', // eslint-disable-line @typescript-eslint/no-duplicate-enum-values
  DEMO_4_2 = 'app-tour-step5-tooltip2', // eslint-disable-line @typescript-eslint/no-duplicate-enum-values

  RECOVERY_1_1 = 'recovery-tour-tooltip-1',
  RECOVERY_1_2 = 'recovery-tour-tooltip-2',
  RECOVERY_2_1 = 'recovery-tour-tooltip-3',
  RECOVERY_3_1 = 'recovery-tour-tooltip-4',
  RECOVERY_4_1 = 'recovery-tour-tooltip-5',

  PARTNER_1_1 = 'partner-tour-step1-tooltip1',
  PARTNER_2_1 = 'partner-tour-step1-tooltip2',
  PARTNER_3_1 = 'partner-tour-step1-tooltip3',
  PARTNER_4_1 = 'partner-tour-step1-tooltip4',
  PARTNER_5_1 = 'partner-tour-step1-tooltip5',

  POSTPARTUM_1_1 = 'postpartum-tour-step1-tooltip1',
  POSTPARTUM_1_2 = 'postpartum-tour-step1-tooltip2',
  POSTPARTUM_2_1 = 'postpartum-tour-step1-tooltip3',
  POSTPARTUM_2_2 = 'postpartum-tour-step1-tooltip4',
  POSTPARTUM_3_1 = 'postpartum-tour-step2-tooltip1',
  POSTPARTUM_4_1 = 'postpartum-tour-step3-tooltip1',
  POSTPARTUM_5_1 = 'postpartum-tour-step4-tooltip1',
  POSTPARTUM_6_1 = 'postpartum-tour-step5-tooltip1',
}

// TODO: maybe rename these keys to xxx-xxx-xxx--GOAL convention
export const TOUR_TOOLTIP_BY_GOAL: Record<Goal, StringMap> = {
  [Goal.PREVENT]: {},
  [Goal.PLAN]: {
    [TourTooltip.APP_1_2]: 'app-tour-plan-step1-tooltip2',
    [TourTooltip.DEMO_1_2]: 'demo-tour-plan-step1-tooltip1',
    [TourTooltip.DEMO_2_2]: 'demo-tour-plan-step2-tooltip2',
    [TourTooltip.DEMO_2_3]: 'demo-tour-plan-step2-tooltip1',
  },
  [Goal.PREGNANT]: {},
  [Goal.RECOVERY]: {},
  [Goal.POSTPARTUM]: {},
}

export const TOUR_TOOLTIP_BY_HWID: Record<HardwareId, StringMap> = {
  [HardwareId.ORAL_THERMOMETER]: {},
  [HardwareId.UEBE_THERMOMETER]: {},
  [HardwareId.T3_THERMOMETER]: {},
  [HardwareId.OURA]: {
    [TourTooltip.APP_6_1]: 'app-tour-step6-tooltip1--OURA',
  },
  [HardwareId.APPLE_WATCH]: {
    [TourTooltip.APP_6_1]: 'app-tour-step6-tooltip1--APPLE_WATCH',
  },
  [HardwareId.B1]: {},
  [HardwareId.SAMSUNG_RING]: {},
}

export enum TourId {
  APP = 'APP',
  DEMO = 'DEMO',
  RECOVERY = 'RECOVERY',
  PARTNER = 'PARTNER',
  POSTPARTUM = 'POSTPARTUM',
}

export const ANALYTICS_BY_TOUR: Record<TourId, string> = {
  [TourId.APP]: 'tour-in-app',
  [TourId.DEMO]: 'tour-demo',
  [TourId.RECOVERY]: 'tour-recovery',
  [TourId.PARTNER]: 'tour-partner',
  [TourId.POSTPARTUM]: 'tour-postpartum',
}

export const ANALYTICS_BY_TOUR_AND_GOAL = {
  [Goal.PREVENT]: {
    [TourId.APP]: 'tour-in-app-prevent',
    [TourId.DEMO]: 'tour-demo-prevent',
  },
  [Goal.PLAN]: {
    [TourId.APP]: 'tour-in-app-plan',
    [TourId.DEMO]: 'tour-demo-plan',
  },
}

export const TOUR_ROUTE_BY_STEP: Record<TourId, any> = {
  [TourId.APP]: {
    1: ROUTES.TodayPage,
    2: ROUTES.MonthPage,
    3: ROUTES.InsightsPage,
    4: ROUTES.MessagesPage,
    5: ROUTES.LearnPage,
    6: ROUTES.AddData,
    7: undefined,
  },

  [TourId.DEMO]: {
    1: ROUTES.TodayPage,
    2: ROUTES.MonthPage,
    3: ROUTES.MessagesPage,
    4: ROUTES.LearnPage,
  },

  [TourId.RECOVERY]: {
    1: ROUTES.TodayPage,
    2: undefined,
    3: ROUTES.AddData,
    4: undefined,
  },

  [TourId.PARTNER]: {
    1: ROUTES.PartnerPage,
    2: undefined,
    3: undefined,
    4: undefined,
    5: undefined,
  },

  [TourId.POSTPARTUM]: {
    1: ROUTES.TodayPage,
    2: undefined,
    3: ROUTES.MonthPage,
    4: ROUTES.MessagesPage,
    5: ROUTES.LearnPage,
    6: ROUTES.AddData,
  },
}

export const TOUR_BY_GOAL: Partial<Record<Goal, TourId>> = {
  [Goal.POSTPARTUM]: TourId.POSTPARTUM,
  [Goal.RECOVERY]: TourId.RECOVERY,
}
