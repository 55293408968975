import { Goal } from '@naturalcycles/shared'
import { ICON } from './cnst/icons.cnst'

export const appVer = '5.3.12'
export const medicalDeviceUID = '(01)7350014630285(8012)'

export const contentShowTimeout = 150

export const slideOptions = {
  shortSwipes: false,
  longSwipesRatio: 0.1,
  longSwipesMs: 50,
}

export const autoplaySlidesOptions = {
  config: {
    speed: 700,
  },

  autoplay: {
    delay: 4500,
    disableOnInteraction: false,
  },

  pagination: {
    el: '.swiper-pagination',
  },
}

/**
 * Regulatory logo by goal
 * Ex: Recovery and Postpartum logos are shown as Plan
 */
export const LOGO_BY_GOAL: Record<Goal, ICON> = {
  [Goal.PREVENT]: ICON.NC_LOGO_PREVENT,
  [Goal.PLAN]: ICON.NC_LOGO_PLAN,
  [Goal.PREGNANT]: ICON.NC_LOGO_PREGNANT,
  [Goal.POSTPARTUM]: ICON.NC_LOGO_POSTPARTUM,
  /**
   * Recovery goal is shown as Plan goal
   */
  [Goal.RECOVERY]: ICON.NC_LOGO_PLAN,
}

export const EXPLICIT_LOGO_BY_GOAL: Record<Goal, ICON> = {
  [Goal.PREVENT]: ICON.NC_LOGO_PREVENT,
  [Goal.PLAN]: ICON.NC_LOGO_PLAN,
  [Goal.PREGNANT]: ICON.NC_LOGO_PREGNANT,
  [Goal.RECOVERY]: ICON.NC_LOGO_RECOVERY,
  [Goal.POSTPARTUM]: ICON.NC_LOGO_POSTPARTUM,
}

export const PRODUCT_NAME_BY_GOAL: Record<Goal, string> = {
  [Goal.PREVENT]: 'txt-goal--1',
  [Goal.PLAN]: 'txt-goal--2',
  [Goal.PREGNANT]: 'txt-goal--3',
  [Goal.RECOVERY]: 'txt-goal--4',
  [Goal.POSTPARTUM]: 'txt-goal--5',
}
