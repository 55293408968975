import { CommonModule } from '@angular/common'
import { Component, inject, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { decorate, ErrorHandlerType, loader, LoaderType } from '@app/decorators/decorators'
import { SnackbarModal } from '@app/modals/snackbar/snackbar.modal'
import { TreeViewModal } from '@app/modals/tree-view/tree-view.modal'
import { BaseModal } from '@app/pages/base.modal'
import { SharedModule } from '@app/shared.module'
import { AccountService } from '@app/srv/account.service'
import { AdminService } from '@app/srv/admin.service'
import { AppleWatchService } from '@app/srv/appleWatch.service'
import { di } from '@app/srv/di.service'
import { EventService } from '@app/srv/event.service'
import { forceExperimentPrefix } from '@app/srv/experiment.service'
import { HealthKitService } from '@app/srv/healthkit/healthkit.service'
import { PartnerService } from '@app/srv/partner.service'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { OuraDataType, QAService } from '@app/srv/qa.service'
import { SessionService } from '@app/srv/session.service'
import { dispatch, getState, select2 } from '@app/srv/store.service'
import { TourId } from '@app/srv/tour.cnst'
import { TourService } from '@app/srv/tour.service'
import { Clipboard } from '@capacitor/clipboard'
import { IonicModule } from '@ionic/angular'
import {
  _objectEntries,
  _range,
  AppError,
  IsoDateString,
  localDate,
  pHang,
} from '@naturalcycles/js-lib'
import { Experiment, getBucket, Goal, HardwareId, ProductKey } from '@naturalcycles/shared'
import { nativeCrash } from '@sentry/capacitor'
import { ExternalApp, ROUTES } from '@src/app/cnst/nav.cnst'
import { NavService } from '@src/app/srv/nav.service'
import { OrderService } from '@src/app/srv/order.service'
import { env } from '@src/environments/environment'
import { BehaviorSubject } from 'rxjs'

// TODO: Move it to shared if this concept will continue living
const FEATURE_FLAGS = [Experiment.FEATURE_FLAG_OURA_BG_SYNC]

@Component({
  selector: 'page-hub',
  standalone: true,
  imports: [IonicModule, SharedModule, CommonModule, TreeViewModal],
  templateUrl: './hub.page.html',
})
export class HubPage extends BaseModal implements OnInit {
  className = 'HubPage'

  protected override blockPopups = false

  public qaService = inject(QAService)
  private accountService = inject(AccountService)
  private appleWatchService = inject(AppleWatchService)
  private eventService = inject(EventService)
  private healthKitService = inject(HealthKitService)
  private partnerService = inject(PartnerService)
  private popupController = inject(PopupController)
  private sessionService = inject(SessionService)
  private tourService = inject(TourService)
  private router = inject(Router)
  private orderService = inject(OrderService)
  private navService = inject(NavService)

  public readonly OuraDataType = OuraDataType

  public completeDate$ = select2(s => s.account.completeDate)
  public goal$ = select2(s => s.account.goal)
  public hardwareId$ = select2(s => s.account.hwId)
  public accountId$ = select2(s => s.account.id)
  public externalAccountId$ = select2(s => s.account.externalAccountId)
  public partnerId$ = select2(s => s.partnerAccount?.id)
  public partners$ = select2(s => s.partners)
  public ouraAuthorized$ = select2(s => s.oura?.authorized)
  public assignments$ = select2(s => s.experiment.assignments)

  public enabledFeatureFlags$ = new BehaviorSubject<Experiment[]>([])

  public personas!: any[]

  public showDangerousFeatures = false

  public readonly HardwareId = HardwareId
  public readonly ProductKey = ProductKey
  public readonly Goal = Goal
  public readonly Experiment = Experiment
  public readonly ExternalApp = ExternalApp

  public skipOptions: { goal: Goal; label: string }[] = [
    { goal: Goal.PREVENT, label: 'Prevent' },
    { goal: Goal.PLAN, label: 'Plan' },
    { goal: Goal.PREGNANT, label: 'Pregnant' },
    { goal: Goal.POSTPARTUM, label: 'Postpartum' },
  ]

  public async ngOnInit(): Promise<void> {
    this.personas = await this.qaService.getPersonas()
    const { account } = getState()

    if (account.partnersEnabled) {
      await this.partnerService.getPartners()
    }

    if (!env.prod || account.testUser) {
      this.showDangerousFeatures = true
    }

    this.subscriptions.push(
      this.assignments$.subscribe(assignments => {
        const value = FEATURE_FLAGS.filter(ff => {
          return _objectEntries(assignments)
            .map(([id, assignment]) => {
              if (id && getBucket(assignment) === 'test') return id
            })
            .includes(ff)
        })

        this.enabledFeatureFlags$.next(value)
      }),
    )
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  public async addPartner(): Promise<void> {
    await this.qaService.addPartner()
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  public async loginToPartner(id?: string): Promise<void> {
    id ||= prompt('partner id or email') || undefined

    if (!id) return

    await this.qaService.loginToPartner(id)
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  public async logout(): Promise<void> {
    await this.sessionService.logout()
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  public async logoutFromRedDot(): Promise<void> {
    di.get(AdminService).redirectToLogout()
    await pHang()
  }

  public async loginToAccount(accId?: string): Promise<void> {
    const idOrEmail = accId || prompt('accountId or email')
    if (!idOrEmail) return
    await this.qaService.loginToAccount(idOrEmail)
  }

  public async loginToPersona(code: string): Promise<void> {
    await this.qaService.loginToPersona(code)
  }

  public async startTour(): Promise<void> {
    const { goal, demoMode } = getState().account
    const isPartner = !!getState().partnerAccount

    if (isPartner) {
      void this.tourService.startTour(TourId.PARTNER)
    } else if (goal === Goal.RECOVERY) {
      void this.tourService.startTour(TourId.RECOVERY)
    } else if (goal === Goal.POSTPARTUM) {
      void this.tourService.startTour(TourId.POSTPARTUM)
    } else if (demoMode) {
      void this.tourService.startTour(TourId.DEMO)
    } else {
      void this.tourService.startTour(TourId.APP)
    }

    void this.dismissModal()
  }

  @loader(LoaderType.BUTTON)
  public async showState(): Promise<void> {
    const modal = await this.popupController.presentModal(
      {
        component: TreeViewModal,
        cssClass: ['modal--alert'],
        componentProps: { data: getState() },
      },
      'modal-treeView',
      Priority.IMMEDIATE,
    )
    await modal.onDidDismiss()
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  public async createMessage(): Promise<void> {
    const msgKey = prompt('msgkey')
    if (!msgKey) return

    await this.qaService.createMessage(msgKey)

    await this.dismissModal()
  }

  public clearUserSettings(): void {
    dispatch('clearUserSettings')
    dispatch('setLastAutoOpen', undefined)
    void this.dismissModal()
  }

  public clearAddDataState(): void {
    dispatch('resetAddData')
    void this.dismissModal()
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  public async setTestBucket(): Promise<void> {
    const experimentId = prompt('Experiment ID')?.toUpperCase()
    if (!experimentId) return

    const bucket = prompt('Bucket name')
    if (!bucket) return

    localStorage.setItem(`${forceExperimentPrefix}${experimentId}`, bucket)
    location.reload()
    await pHang()
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  public async clearTestBuckets(): Promise<void> {
    const { assignments } = getState().experiment

    Object.keys(assignments).forEach(id => {
      localStorage.removeItem(`${forceExperimentPrefix}${id}`)
    })

    location.reload()
    await pHang()
  }

  public async skipAppQuiz(goal: Goal): Promise<void> {
    await this.qaService.bypassQuiz(goal)
    void this.dismissModal()
  }

  public async setMockLocation(event: CustomEvent): Promise<void> {
    let countryCode = event.detail.value as string | undefined

    if (countryCode === 'XXX') {
      countryCode = prompt('Enter country code (2 characters)')!
    } else if (countryCode === 'YYY') {
      localStorage.removeItem('e2eCountry')
      location.reload()
      await pHang()
    }

    if (!countryCode) return

    localStorage.setItem('e2eCountry', countryCode.toUpperCase())
    location.reload()
    await pHang()
  }

  public async addData(): Promise<void> {
    const numberOfCycles = parseInt(prompt('Number of cycles', '1')!) || 1
    if (!numberOfCycles) return
    const cycleDay = parseInt(prompt('Cycle day', '13')!) || 13
    if (!cycleDay) return

    const includeTrackers = window.confirm(
      'Do you want to add trackers as well? Cancel to skip adding trackers.',
    )

    void this.dismissModal()

    await this.qaService.addDataToPersona(
      numberOfCycles,
      cycleDay,
      undefined,
      undefined,
      !includeTrackers,
    )
  }

  public async makePregnant(): Promise<void> {
    const numberOfCycles = parseInt(prompt('Number of cycles', '1')!) || 1

    void this.dismissModal()

    await this.qaService.makePersonaPregnant(numberOfCycles)
  }

  public async addInconsistentData(): Promise<void> {
    const numberOfCycles = parseInt(prompt('Number of cycles', '1')!) || 1
    const cycleDay = parseInt(prompt('Cycle day', '13')!) || 13
    const cycleLength = parseInt(prompt('Cycle length', '32')!) || 32
    const measureRatio =
      parseFloat(prompt('Measure ratio (0.12 means %88 measure)', '0.12')!) || 0.12

    void this.dismissModal()

    await this.qaService.addDataToPersona(numberOfCycles, cycleDay, cycleLength, measureRatio)
  }

  public async makePregnantWithDate(): Promise<void> {
    const numberOfCycles = parseInt(prompt('Number of cycles', '1')!) || 1
    const pregDate = prompt('Date - go back only a few days please!', localDate.todayString())!

    void this.dismissModal()

    await this.qaService.makePersonaPregnant(numberOfCycles, pregDate)
  }

  public async addRealPregnantData(): Promise<void> {
    void this.dismissModal()

    await this.qaService.addRealPregnantData()
  }

  public async changeCompleteDate(): Promise<void> {
    const { account } = getState()
    const completeDate: IsoDateString | null = prompt(
      `Current date is: ${account.completeDate}. What is the new date?`,
    )
    if (!completeDate) {
      alert('Wrong date 📆 The date format is YYYY-MM-DD')
      return
    }

    void this.dismissModal()

    await this.qaService.setCompleteDate(completeDate)
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  public async changeGoal(event: CustomEvent): Promise<void> {
    const { account } = getState()
    if (account.completeDate) {
      await this.accountService.changeGoal({ goal: event.detail.value })
    } else {
      await this.accountService.patch({ goal: event.detail.value })
    }

    location.reload()
    await pHang()
  }

  public showPerf(): void {
    const prflog = window.prflogString()
    alert(prflog)
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  public triggerErrorDialog(): void {
    this.triggerError()
  }

  public triggerError(): void {
    if (window.Capacitor.isNativePlatform()) {
      nativeCrash()
    } else {
      const cause = new Error('hey I have caused the error')

      throw new AppError(
        `Test error`,
        {
          some: 'value',
        },
        {
          cause,
        },
      )
    }
  }

  public async deleteHKCache(): Promise<void> {
    await this.healthKitService.deleteHashFile()
    void this.dismissModal()
  }

  public toggleHardwareId(event: CustomEvent): void {
    const id = event.detail.value

    void this.qaService.toggleHardwareId(id)
  }

  public buyThermometer(event: CustomEvent): void {
    const key = event.detail.value as ProductKey

    void this.qaService.buyThermometer(key)
  }

  public async syncOuraData(dataType?: OuraDataType): Promise<void> {
    await this.qaService.syncOuraData(dataType)

    void this.dismissModal()
  }

  public async disconnectOuraAccount(): Promise<void> {
    await this.qaService.disconnectOuraAccount()

    void this.dismissModal()
  }

  public addAppleWatchData(dayCount?: number): void {
    const endDate =
      prompt('Enter end date (YYYY-MM-DD). Leave blank for today.') || localDate.todayString()
    dayCount ||= Number(prompt('Number of days'))

    if (!dayCount) {
      alert('Try again! 🙁')
    }

    let temp: number
    if (dayCount === 1) {
      temp = Number(prompt('Temperature (leave blank to get a random value)'))
    }

    this.appleWatchService.mockWristTemps = _range(0, dayCount).map(i =>
      this.appleWatchService.mockWristTemperatureSample(i, endDate, temp),
    )

    void this.dismissModal()
    this.eventService.onResume$.next()
  }

  public resetWristTemperatureMock(): void {
    this.appleWatchService.mockWristTemps = undefined
  }

  public async copyToClipboard(text: string): Promise<void> {
    // eslint-disable-next-line id-blacklist
    await Clipboard.write({ string: text })

    void this.popupController.presentModal(
      {
        component: SnackbarModal,
        cssClass: [
          'modal--alert',
          'modal--snackbar',
          'modal--snackbar--secondary',
          'modal--snackbar--bottom',
        ],
        showBackdrop: false,
        componentProps: {
          text: `Copied to clipboard: ${text}`,
        },
      },
      'hub-copy-to-clipboard-snackbar',
      Priority.IMMEDIATE,
    )
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  public async changeFeatureFlag(event: CustomEvent): Promise<void> {
    const experimentIds = event.detail.value

    FEATURE_FLAGS.forEach(ff => {
      localStorage.setItem(
        `${forceExperimentPrefix}${ff}`,
        experimentIds.includes(ff) ? 'test' : 'control',
      )
    })

    location.reload()
    await pHang()
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  public async triggerPayment(): Promise<void> {
    if (env.prod) return
    if (this.router.url !== ROUTES.PaymentPage) {
      alert('You can only trigger payment from the payment page')
      return
    }

    const nonce = prompt('Enter nonce', 'fake-valid-nonce')
    if (!nonce) return

    this.orderService.redDotNonce$.next(nonce)
    void this.dismissModal()
  }

  public async checkIfAppIsInstalled(appId: ExternalApp): Promise<void> {
    const isAppInstalled = await this.navService.canOpenExternalApp(appId)
    const appKey = Object.keys(ExternalApp).find(
      key => ExternalApp[key as keyof typeof ExternalApp] === appId,
    )
    alert(`App ${appKey} is ${isAppInstalled ? 'installed' : 'not installed'}`)
  }
}
