import { inject, Injectable } from '@angular/core'
import { COLOR } from '@app/cnst/color.cnst'
import { UFEntry } from '@app/model/uf.model'
import {
  GRAPH_ICON_BY_DATAFLAG,
  GRAPH_ICON_BY_LH,
  GRAPH_ICON_BY_PREGTEST,
  GRAPH_ICON_BY_PREGTEST_PREVENT,
  GRAPH_ICON_BY_SEX,
  TRACKER_KEYS,
} from '@app/pages/graph/graph.cnst'
import { AppSettingsFM } from '@app/srv/appSettings.cnst'
import { select } from '@app/srv/store.service'
import { UFService } from '@app/srv/uf.service'
import { distinctUntilDeeplyChanged } from '@app/util/distinctUntilDeeplyChanged'
import { _stringMapValues, StringMap } from '@naturalcycles/js-lib'
import {
  DataFlag,
  Goal,
  HadSex,
  OvulationStatus,
  UFColor,
  UserFertility,
} from '@naturalcycles/shared'
import { dayjs } from '@naturalcycles/time-lib'
import { AppearanceService, AppearanceSettings } from '@src/app/srv/appearance.service'
import { Observable } from 'rxjs'
import { combineLatestWith, map, shareReplay, skipWhile } from 'rxjs/operators'
import {
  ColorLineTemperature,
  CompareItem,
  CycleGraphEntry,
  FertilityColorLine,
  GraphArea,
  GraphEntry,
  HolisticGraphEntry,
  MensArea,
  MensStatus,
  OvulationArea,
  STATUS_BY_MENS,
  Timestamp,
  TrackerIcon,
  YAxis,
} from './graph.model'
import { GraphDataHolisticService } from './graphData.holistic.service'

export const MS_DAY = 86400000
const MS_HALF_DAY = MS_DAY / 2
const MAX_MISSING_TEMPS = 9

export const TODAY_DAYS = 14
const TODAY_PREDICTION_DAYS = 3
/**
 * @description Everything for creating data for the graphs
 */
@Injectable({ providedIn: 'root' })
export class GraphDataService {
  private ufService = inject(UFService)
  private holisticService = inject(GraphDataHolisticService)
  private appearanceService = inject(AppearanceService)
  @select(['appSettings'])
  private appSettings$!: Observable<AppSettingsFM | null>

  private graphEntries$ = this.ufService.ufEntries$.pipe(
    combineLatestWith(this.ufService.showFertilityStatus$),
    map(([ufEntries, showFertilityStatus]) =>
      this._ufToGraphEntries(ufEntries, showFertilityStatus),
    ),
    shareReplay(),
  )

  public cycleGraphEntries$ = this.graphEntries$.pipe(
    combineLatestWith(this.ufService.uf$),
    map(([entries, uf]) => this._entriesToCycleGraphEntries(entries, uf)),
  )

  public fertilityColorLines$ = this.graphEntries$.pipe(
    distinctUntilDeeplyChanged(),
    map(entries => this._getFertilityColorLines(entries)),
    shareReplay(),
  )

  public mensAreas$ = this.graphEntries$.pipe(
    distinctUntilDeeplyChanged(),
    map(entries => this._getMensAreas(entries)),
    shareReplay(),
  )

  public fertileAreas$ = this.graphEntries$.pipe(
    distinctUntilDeeplyChanged(),
    map(entries => this._getFertileAreas(entries)),
    shareReplay(),
  )

  public ovulationAreas$ = this.ufService.uf$.pipe(
    combineLatestWith(this.graphEntries$),
    distinctUntilDeeplyChanged(),
    map(([uf, entries]) => this._getOvulationAreas(uf, entries)),
    shareReplay(),
  )

  public compareItems$ = this.ufService.uf$.pipe(
    combineLatestWith(this.cycleGraphEntries$),
    distinctUntilDeeplyChanged(),
    map(([uf, entries]) => this._graphEntriesToCompareItems(uf, entries)),
    shareReplay(),
  )

  public todayGraphEntries$ = this.ufService.uf$.pipe(
    combineLatestWith(this.cycleGraphEntries$, this.graphEntries$),
    distinctUntilDeeplyChanged(),
    map(([uf, cycleGraphEntries, entries]) =>
      this._entriesToTodayItems(uf, cycleGraphEntries, entries),
    ),
    shareReplay(),
  )

  public holisticGraphEntries$ = this.graphEntries$.pipe(
    combineLatestWith(
      this.appSettings$.pipe(skipWhile(settings => !settings)),
      this.appearanceService.appearance$,
    ),
    map(([entries, settings, appearance]) =>
      this._entriesToHolisticGraphEntries(entries, settings!, appearance),
    ),
  )

  public yTicks$ = this.ufService.uf$.pipe(
    distinctUntilDeeplyChanged(),
    map(uf => {
      const { min, max } = this._getYValues(uf)

      const numOfTicks = this._calculateNumberOfTicks(min, max)

      return this._getYTicks(min, max, numOfTicks)
    }),
    shareReplay(),
  )

  public coverLine$ = this.ufService.uf$.pipe(
    distinctUntilDeeplyChanged(),
    map(uf => this._calculateCoverLine(uf)),
    shareReplay(),
  )

  public getLongestCycle(items: CompareItem[]): number {
    const longest = Math.max(
      ...items.map(
        ({ pregnantCycle, entries }) => (pregnantCycle && entries.length > 90 ? 0 : entries.length), // skip cycle if it is pregnant and longer than 90 days
      ),
      0,
    )

    return longest || 30 // default to 30 if all items are ignored
  }

  public _ufToGraphEntries(ufEntries: UFEntry[], showFertilityStatus: boolean): GraphEntry[] {
    const cycleLengths: StringMap<number> = {}

    ufEntries.forEach(e => {
      cycleLengths[e.nCycle] ||= 0
      cycleLengths[e.nCycle]!++
    })

    return ufEntries.map(e => {
      const { mens: _, fertilityStatus: _2, color, ...entry } = e

      const midCycle = Math.round((cycleLengths[entry.nCycle] || 0) / 2)

      return {
        ...entry,
        timestamp: this.getTimestamp(entry.date),
        color: entry.prediction
          ? ''
          : this.ufService.hexFromColor(entry.colorClass, showFertilityStatus),
        nCycle: entry.nCycle,
        mens: this.getMensStatus(e),
        fertile: color === UFColor.RED || color === UFColor.YELLOW,
        middleOfCycle: entry.cd === midCycle,
        temperature: entry.deviationReasons ? undefined : entry.temperature,
      }
    })
  }

  private getMensStatus(entry: UFEntry): MensStatus | undefined {
    const { mens } = entry || {}

    // mens from daily entry
    if (mens) return STATUS_BY_MENS[mens]

    // mens from prediction
    if (entry.code.mens && (entry.prediction || entry.today)) return MensStatus.PREDICTION

    // mens from colorMap (algo added)
    if (entry.code.mens) return MensStatus.CONFIRMED
  }

  public _entriesToCycleGraphEntries(entries: GraphEntry[], uf: UserFertility): CycleGraphEntry[] {
    const currentCycle = entries.find(e => e.today)?.nCycle

    const cycleEntries = entries.filter(
      ({ prediction, nCycle, code }) => !prediction || (nCycle === currentCycle && !code.defPreg),
    )

    const { oave } = uf
    const currentCycleEntries = entries.filter(e => e.nCycle === currentCycle)
    const lastEntryCd = currentCycleEntries[currentCycleEntries.length - 1]!.cd

    const ovulationDay = Math.round(oave)
    const { min, max } = this._getYValues(uf)
    const variation = (max - min) / 2.2

    const coverLine = this._calculateCoverLine(uf) || (min + max) / 2

    return cycleEntries.map(entry => {
      const { prediction, sex, sexFlags } = entry

      if (prediction) {
        entry.temperature = this.generatePredictionTemperature(
          entry.cd,
          ovulationDay,
          lastEntryCd,
          variation,
          coverLine,
        )
      }

      return {
        ...entry,
        sexIcon: this.getSexIcon(sex, sexFlags),
        trackerIcon: this.getTrackerIcon(entry),
      }
    })
  }

  public _entriesToTodayItems(
    uf: UserFertility,
    cycleGraphEntries: CycleGraphEntry[],
    graphEntries: GraphEntry[],
  ): CompareItem {
    const currentCycle = uf.ncycles

    const currentCycleEntries = graphEntries.filter(e => e.nCycle >= currentCycle)

    const todayIndex = currentCycleEntries.findIndex(e => e.today)

    const pastDays = TODAY_DAYS - TODAY_PREDICTION_DAYS

    const startIndex = todayIndex > pastDays ? todayIndex + 1 - pastDays : 0

    const entries = currentCycleEntries.slice(startIndex, startIndex + TODAY_DAYS)

    // use more entries to get the lines, to make sure the line goes all the way
    const lineEntries = currentCycleEntries.slice(0, startIndex + TODAY_DAYS + 1)

    // get predicted temperature from cycle graph entries
    entries.forEach(entry => {
      if (!entry.prediction || entry.temperature) return

      entry.temperature = cycleGraphEntries.find(e => e.date === entry.date)?.temperature
    })

    return {
      nCycle: currentCycle,
      entries,
      mensAreas: this._getMensAreas(lineEntries),
      ovulationAreas: this._getOvulationAreas(uf, entries),
      fertilityColorLines: this._getFertilityColorLines(lineEntries),
    }
  }

  public _entriesToHolisticGraphEntries(
    entries: GraphEntry[],
    settings: AppSettingsFM,
    appearance: AppearanceSettings = AppearanceSettings.LIGHT,
  ): HolisticGraphEntry[] {
    return entries.map(e => {
      const trackers = this.holisticService.getTrackersForEntry(e, settings, appearance)

      const {
        mensQuantity: _,
        libido: _2,
        pains: _3,
        moods: _4,
        hwIdChange: _5,
        sleep: _6,
        ...entry
      } = e

      return {
        ...entry,
        ...trackers,
        hasTrackers: this.hasTrackers({ ...entry, ...trackers }),
      }
    })
  }

  private _graphEntriesToCompareItems(uf: UserFertility, entries: GraphEntry[]): CompareItem[] {
    const i: StringMap<CompareItem> = {}

    entries.forEach(entry => {
      const { nCycle, goal } = entry

      // TODO: should probably check if undefined; can be 0
      if (!nCycle) return

      // create new CompareItem
      if (!i[nCycle]) {
        const entries: GraphEntry[] = []

        // if cycle is not starting on CD 1, add empty days before so the CD's match
        if (entry.cd !== 1) {
          for (let cd = entry.cd - 1; cd > 0; cd--) {
            const index = entry.cd - cd
            const date = dayjs(entry.date).subtract(index, 'day').toISODate()

            entries.unshift({
              cd,
              date,
              timestamp: this.getTimestamp(date),
              color: '',
              nCycle,
              goal,
              code: {},
            })
          }
        }

        i[nCycle] = {
          nCycle,
          entries,
          mensAreas: [],
          ovulationAreas: [],
          fertilityColorLines: [],
        }
      }

      // add entry to ongoing compareItem
      i[nCycle]?.entries.push(entry)
    })

    const items = _stringMapValues(i)

    for (const item of items) {
      item.mensAreas = this._getMensAreas(item.entries)
      item.ovulationAreas = this._getOvulationAreas(uf, item.entries)
      item.fertilityColorLines = this._getFertilityColorLines(item.entries)
      item.pregnantCycle = item.entries[item.entries.length - 1]?.code?.defPreg
    }

    return items
  }

  private hasTrackers(graphEntry: HolisticGraphEntry): boolean {
    const keysWithoutPredictions = Object.keys(graphEntry).filter(key => {
      if (key === 'ovulationStatus') {
        return (
          graphEntry.ovulationStatus !== OvulationStatus.OVU_PREDICTION &&
          graphEntry.ovulationStatus !== OvulationStatus.OVU_DAY
        )
      }

      if (key === 'mens') {
        return graphEntry.mens !== MensStatus.PREDICTION
      }

      if (key === 'mood') {
        // No trackers added if the only mood is a PMS prediction
        return !(
          graphEntry.mood?.icons?.length === 1 && graphEntry.dataFlags?.includes(DataFlag.MOOD_PMS)
        )
      }

      return true
    })

    return keysWithoutPredictions.some(
      key => TRACKER_KEYS.includes(key as keyof HolisticGraphEntry) && !!graphEntry[key],
    )
  }

  private getSexIcon(sex?: HadSex, sexFlags?: DataFlag[]): TrackerIcon | undefined {
    const icon = GRAPH_ICON_BY_SEX[sex!] || GRAPH_ICON_BY_DATAFLAG[sexFlags?.[0]!]

    if (!icon) return

    return {
      icon,
      backgroundColor: COLOR.SEX,
    }
  }

  private getTrackerIcon(entry: GraphEntry): TrackerIcon | undefined {
    const items: TrackerIcon[] = []

    // in importance order, if there are multiple trackers the color will be taken from the first one

    // emergency pill or iud
    entry.emergencyFlags?.forEach(dataFlag => {
      const icon = GRAPH_ICON_BY_DATAFLAG[dataFlag]

      if (!icon) return

      items.push({
        icon,
        backgroundColor: COLOR.MEDIUM,
      })
    })

    // preg test
    if (entry.pregTest) {
      if (entry.goal === Goal.PREVENT) {
        items.push({
          icon: GRAPH_ICON_BY_PREGTEST_PREVENT[entry.pregTest],
          backgroundColor: COLOR.MEDIUM,
        })
      } else {
        items.push({
          icon: GRAPH_ICON_BY_PREGTEST[entry.pregTest],
          backgroundColor: COLOR.PREGNANT,
        })
      }
    }

    // lh test
    if (entry.lhTest) {
      items.push({
        icon: GRAPH_ICON_BY_LH[entry.lhTest],
        backgroundColor: COLOR.LH,
      })
    }

    ;[...(entry.deviationReasons || []), ...(entry.ouraFlags || [])].forEach(dataFlag => {
      const icon = GRAPH_ICON_BY_DATAFLAG[dataFlag]

      if (!icon) return

      items.push({
        icon,
        backgroundColor: COLOR.TEMPERATURE,
      })
    })

    if (!items.length) return
    if (items.length === 1) return items[0]

    return {
      label: `${items.length}`,
      backgroundColor: items[0]!.backgroundColor,
    }
  }

  private getTimestamp(date: string): Timestamp {
    const start = dayjs(date).startOf('day').unixMillis()

    return {
      start,
      end: start + MS_DAY - 1, // add one day minus one millisecond to stay on the same date
      mid: start + MS_HALF_DAY, // mid day
    }
  }

  public _getFertilityColorLines(_entries: GraphEntry[]): FertilityColorLine[] {
    const areas: FertilityColorLine[] = []

    // only include entries with temperature and entries who are the first of its color
    let entries = _entries.filter(
      (e, i) => !!e.temperature || (_entries[i - 1] && e.color !== _entries[i - 1]?.color),
    )

    const todayEntry = _entries.find(entry => entry.today)

    if (todayEntry?.goal === Goal.POSTPARTUM && todayEntry?.isCycleAfterBirth) {
      const shouldHidePredictions = !_entries.some(
        entry => entry.date >= todayEntry?.cycleStartDate! && entry.code.ovulation, // no confirmed ovulation in current cycle
      )

      if (shouldHidePredictions) {
        entries = entries.filter(e => !e.prediction)
      }
    }

    entries.forEach((entry, i) => {
      const previousTemperature = entries
        .slice(0, i)
        .reverse()
        .find(e => !!e.temperature)
      const nextTemperature = entries.slice(i).find(e => !!e.temperature)

      const { days, temperaturePer12H } = this.getDifference(nextTemperature, previousTemperature)

      // create new array if:
      // - color is not same
      // - X missing temps
      // - shift between past & prediction
      if (
        (nextTemperature && entry.color !== entries[i - 1]?.color) ||
        (days && days > MAX_MISSING_TEMPS) ||
        (entry.prediction && !entries[i - 1]?.prediction)
      ) {
        const temperatures: ColorLineTemperature[] = []

        // prediction
        if (entry.prediction && entry.temperature) {
          temperatures.push({
            x: (entry.prediction && todayEntry?.timestamp.mid) || entry.timestamp.mid,
            y: (entry.prediction && todayEntry?.temperature) || entry.temperature,
          })
        } else {
          // add temperature if it exists
          if (entry.temperature) {
            temperatures.push({
              x: entry.timestamp.mid,
              y: entry.temperature,
            })
          }

          // add points inbetween days if lines should be connected
          // lines should be connected if there is max {MAX_MISSING_TEMPS} days inbetween the two temperatures
          if (
            days &&
            temperaturePer12H !== undefined &&
            days <= MAX_MISSING_TEMPS &&
            previousTemperature?.temperature
          ) {
            if (nextTemperature?.prediction) return

            const index = (entry.timestamp.mid - previousTemperature.timestamp.mid) / MS_DAY - 1
            const tempDiffPerDay = temperaturePer12H * 2

            const x = entry.timestamp.start
            const y =
              previousTemperature.temperature +
              (previousTemperature.today ? 0 : index * tempDiffPerDay + temperaturePer12H)

            // add to previous area
            areas[areas.length - 1]!.temperatures.push({ x, y })

            // add to beginning of this area
            temperatures.unshift({ x, y })
          }
        }

        areas.push({
          color: entry.color,
          temperatures,
          prediction: entry.prediction,
        })
      } else if (entry.temperature) {
        // add to the ongoing area
        areas[areas.length - 1]!.temperatures.push({
          x: entry.timestamp.mid,
          y: entry.temperature,
        })
      }
    })

    return areas
  }

  private getDifference(
    a?: GraphEntry,
    b?: GraphEntry,
  ): { days?: number; temperaturePer12H?: number } {
    if (!a?.temperature || !b?.temperature) return {}

    const days = Math.round((a.timestamp.mid - b.timestamp.mid) / MS_DAY)

    if (days > MAX_MISSING_TEMPS) return { days }

    // temperature diff is diff per 12 hours because the color changes in between two days
    const temperaturePer12H = (a.temperature - b.temperature) / days / 2

    return { days, temperaturePer12H }
  }

  private generatePredictionTemperature(
    cd: number,
    ovulationDay: number,
    cycleLength: number,
    variation: number,
    coverLineTemp: number,
  ): number {
    let param = 0.8
    let afterOvulation = -1

    if (cd > ovulationDay) {
      afterOvulation = 1
      const daysSinceOvulation = cd - ovulationDay
      const lPhaseLength = cycleLength - ovulationDay
      param = Math.abs(lPhaseLength / 2 - daysSinceOvulation) / (lPhaseLength / 2)
    } else if (cd < ovulationDay) {
      param = Math.abs(ovulationDay - 3 - cd) / (ovulationDay - 3)
    }

    const sineCorrection = 1 - param * param
    const random = ((Math.random() - 0.5) * variation) / 3

    return coverLineTemp + (1.5 * sineCorrection * afterOvulation * variation) / 2 + random
  }

  public _getMensAreas(entries: GraphEntry[]): MensArea[] {
    const areas: StringMap<GraphEntry[]> = {}
    const mensEntries = entries.filter(entry => entry.mens && entry.mens !== MensStatus.SPOTTING)

    mensEntries.forEach(entry => {
      const { nCycle, mens } = entry

      if (nCycle === undefined) return

      const key = `${nCycle}_${mens}`

      areas[key] ||= []

      areas[key]?.push(entry)
    })

    return _stringMapValues(areas).map(a => {
      const start = a[0]!.timestamp.start
      const end = a[a.length - 1]!.timestamp.end
      const status = a[0]!.mens!

      return {
        timestamp: {
          start,
          end,
          mid: start + Math.round((end - start) / 2),
        },
        status,
      }
    })
  }

  public _getFertileAreas(entries: GraphEntry[]): GraphArea[] {
    const areas: GraphEntry[][] = []
    const fertileEntries = entries.filter(entry => entry.fertile && entry.goal !== Goal.RECOVERY)

    fertileEntries.forEach((entry, i) => {
      // push entry into last array if day difference is one
      if (
        areas.length &&
        fertileEntries[i - 1] &&
        dayjs(entry.date).diff(fertileEntries[i - 1]!.date, 'day') === 1
      ) {
        areas[areas.length - 1]!.push(entry)

        // otherwise start a new area
      } else {
        areas.push([entry])
      }
    })

    return areas.map(a => {
      const start = a[0]!.timestamp.start
      const end = a[a.length - 1]!.timestamp.end

      return {
        timestamp: {
          start,
          end,
          mid: start + Math.round((end - start) / 2),
        },
      }
    })
  }

  public _getOvulationAreas(uf: UserFertility, entries: GraphEntry[]): OvulationArea[] {
    const areas: StringMap<{
      entry: GraphEntry
      ovulationWindowMin?: number
      ovulationWindowMax?: number
    }> = {}
    const ovulationEntries = entries.filter(entry => !!entry.ovulationStatus)

    ovulationEntries.forEach(entry => {
      const { cd, nCycle } = entry

      if (nCycle === undefined) return
      const ovulationWindowMin = uf.ovulationWindowMin[nCycle]
      const ovulationWindowMax = uf.ovulationWindowMax[nCycle]

      // Only add if ovulation window it exists and doesn't match ovulation day
      if (
        !areas[nCycle] &&
        ovulationWindowMin &&
        ovulationWindowMax &&
        (ovulationWindowMin !== cd || ovulationWindowMax !== cd)
      ) {
        areas[nCycle] = {
          entry,
          ovulationWindowMin,
          ovulationWindowMax,
        }
      }
    })

    const ovulationAreas = _stringMapValues(areas).map(
      ({ entry: { cd, ovulationStatus, timestamp }, ovulationWindowMin, ovulationWindowMax }) => {
        const windowStartDiff = cd - (ovulationWindowMin || cd)
        const windowEndDiff = (ovulationWindowMax || cd) - cd

        const start = dayjs(timestamp.start).subtract(windowStartDiff, 'day').unixMillis()
        const end = dayjs(timestamp.end).add(windowEndDiff, 'day').unixMillis()
        return {
          timestamp: {
            start,
            end,
            mid: start + Math.round((end - start) / 2),
          },
          status: ovulationStatus!,
        }
      },
    )

    return ovulationAreas
  }

  public _getYValues(uf: UserFertility): YAxis {
    const {
      lowTempMean,
      highTempMean,
      highTempRMS,
      lowTempRMS,
      pregnantNow,
      pregTempMean,
      pregTempRMS,
      fahrenheit,
    } = uf

    const temperatures = {
      min: fahrenheit ? 96.6 : 35.9,
      max: fahrenheit ? 98.8 : 37.1,
    }

    if (!lowTempMean && !highTempMean) {
      return temperatures
    }

    let minvalue = lowTempMean
    let maxvalue = highTempMean
    let maxRMS = highTempRMS
    const minRMS = lowTempRMS

    if (pregnantNow && pregTempMean && highTempMean && pregTempMean > highTempMean) {
      maxvalue = pregTempMean * 1.004
      maxRMS = pregTempRMS
    }

    let step = 0.5
    let fac = 1
    if (fahrenheit) {
      step = 0.9
      fac = 2
    }

    if (!minvalue || !minRMS) {
      if (!maxvalue || !maxRMS) {
        minvalue = temperatures.min - fac * 0.3
        maxvalue = temperatures.max + fac * 0.3
      } else {
        minvalue = maxvalue - step - 2 * maxRMS
        maxvalue += 2.5 * maxRMS
      }
    } else {
      if (!maxvalue || !maxRMS) {
        maxvalue = minvalue + step + 2 * minRMS
      } else {
        maxvalue += 2.5 * maxRMS
      }
      minvalue -= 2.5 * minRMS
    }

    temperatures.min = minvalue - 0.02 * (maxvalue - minvalue)
    temperatures.min = Math.floor(temperatures.min * 10) / 10
    temperatures.max = maxvalue + 0.05 * (maxvalue - minvalue)

    return temperatures
  }

  public _calculateNumberOfTicks(min: number, max: number): number {
    const diff = Math.max(Math.round((max - min) * 10), 5) // The diff between min and max * 10, min 5
    let tickCount = 4 // Minimum ticks

    do {
      tickCount++
    } while (diff % tickCount !== 0) // Get the lowest possible division remainder

    if (tickCount > 10) tickCount = 6 // if more than 10 ticks, put 6 ticks because thats the default one

    return tickCount
  }

  public _getYTicks(minYValue: number, maxYValue: number, count: number): number[] {
    const diff = Math.round((maxYValue - minYValue) * 10)
    const diffRounded = Math.round(diff / count) * count // Round the diff to spread out even relative to tickCount

    maxYValue = minYValue + diffRounded / 10

    const step = Math.round(((maxYValue - minYValue) / count) * 10) / 10
    const result: number[] = []

    for (let i = 0; i < count; i++) {
      const value = minYValue + i * step
      result.push(value)
    }
    result.push(maxYValue)

    return result
  }

  private _calculateCoverLine(uf: UserFertility): number | undefined {
    const { lowTempMean, highTempMean } = uf

    if (!lowTempMean || !highTempMean) return

    return (lowTempMean + highTempMean) / 2
  }
}
