<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button mode="md" (click)="dismissModal()">
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>
    <ion-title>Debug</ion-title>
  </ion-toolbar>
</ion-header>

@if (
  {
    accountId: accountId$ | async,
    completeDate: completeDate$ | async,
    hardwareId: hardwareId$ | async,
    ouraAuthorized: ouraAuthorized$ | async,
    partnerId: partnerId$ | async,
  };
  as observables
) {
  <ion-content class="hubpage">
    <ul style="list-style: none; font-size: 1.2rem; padding: 0 16px">
      @if (observables.accountId; as id) {
        <li (click)="copyToClipboard(id)" tabindex="0">Account id: {{ id }}</li>
      }

      @if (externalAccountId$ | async; as id) {
        <li (click)="copyToClipboard(id)" tabindex="0">External Account id: {{ id }}</li>
      }

      @if (observables.partnerId; as id) {
        <li (click)="copyToClipboard(id)" tabindex="0">Partner id: {{ id }}</li>
      }

      @if (observables.completeDate; as date) {
        <li>Complete date: {{ date }}</li>
      }
    </ul>

    <ion-list class="ion-no-padding">
      <ion-item-group class="ion-no-padding">
        <ion-item-divider>
          <ion-label>User</ion-label>
        </ion-item-divider>

        @if (personas) {
          <ion-item (click)="loginToAccount()" button>
            <ion-label>Login</ion-label>
          </ion-item>
        }

        @if (observables.accountId) {
          <ion-item (click)="logout()" button>
            <ion-label>Logout from User</ion-label>
          </ion-item>
        }

        @if (observables.partnerId && observables.accountId; as id) {
          <ion-item (click)="loginToAccount(id)" button>
            <ion-label>Back to NC user</ion-label>
          </ion-item>
        }

        @if (!observables.accountId) {
          <div>
            @for (option of skipOptions; track option.goal) {
              <ion-item (click)="skipAppQuiz(option.goal)" button>
                <ion-label>Skip App Quiz ({{ option.label }})</ion-label>
              </ion-item>
            }
          </div>
        }
      </ion-item-group>

      <ion-item-group class="ion-no-padding">
        <ion-item-divider>
          <ion-label>QA</ion-label>
        </ion-item-divider>

        <ion-item (click)="showState()" button loader>
          <ion-label>Show state</ion-label>
        </ion-item>

        @if (observables.completeDate && personas) {
          <ion-item (click)="createMessage()" button>
            <ion-label>Create message</ion-label>
          </ion-item>
        }

        @if ((observables.completeDate && personas) || !!observables.partnerId) {
          <ion-item (click)="startTour()" button>
            <ion-label>Start tour</ion-label>
          </ion-item>
        }

        @if (observables.completeDate && personas) {
          <ion-item (click)="clearUserSettings()" button>
            <ion-label>Clear user settings</ion-label>
          </ion-item>

          <ion-item (click)="clearAddDataState()" button>
            <ion-label>Clear add data state</ion-label>
          </ion-item>
        }

        <ion-item (click)="setTestBucket()" button>
          <ion-label>Set AB test bucket</ion-label>
        </ion-item>

        <ion-item (click)="clearTestBuckets()" button>
          <ion-label>Clear AB test buckets</ion-label>
        </ion-item>

        <ion-item>
          <ion-select
            label="Set mock location"
            [interfaceOptions]="{
              subHeader: 'Set until you log out or into a new user, or clear it below.',
            }"
            [value]="undefined"
            (ionChange)="setMockLocation($any($event))"
          >
            <ion-select-option value="SE">Sweden</ion-select-option>
            <ion-select-option value="GB">United Kingdom</ion-select-option>
            <ion-select-option value="US">USA</ion-select-option>
            <ion-select-option value="CA">Canada</ion-select-option>
            <ion-select-option value="AU">Australia</ion-select-option>
            <ion-select-option value="SG">Singapore</ion-select-option>
            <ion-select-option value="RU">Russia</ion-select-option>
            <ion-select-option value="XXX">Other</ion-select-option>
            <ion-select-option value="YYY">Clear mock location</ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item (click)="triggerPayment()" button>
          <ion-label>Trigger payment</ion-label>
        </ion-item>
      </ion-item-group>

      <!-- UF -->
      @if (showDangerousFeatures && personas.length && observables.completeDate) {
        <ion-item-group>
          <ion-item-divider>
            <ion-label>UF</ion-label>
          </ion-item-divider>

          <ion-item (click)="addData()" button>
            <ion-label>Add data</ion-label>
          </ion-item>

          <ion-item (click)="makePregnant()" button>
            <ion-label>Make pregnant</ion-label>
          </ion-item>

          <ion-item (click)="addInconsistentData()" button>
            <ion-label>Add inconsistent data</ion-label>
          </ion-item>

          <ion-item (click)="makePregnantWithDate()" button>
            <ion-label>Make pregnant(with date)</ion-label>
          </ion-item>

          <ion-item (click)="addRealPregnantData()" button>
            <ion-label>Add real pregnant data</ion-label>
          </ion-item>

          <ion-item (click)="changeCompleteDate()" button>
            <ion-label>Change complete date</ion-label>
          </ion-item>
        </ion-item-group>
      }

      @if (observables.accountId) {
        <ion-item-group>
          <ion-item-divider>
            <ion-label>Goal</ion-label>
          </ion-item-divider>

          <ion-item>
            <ion-select
              label="Change goal"
              [value]="goal$ | async"
              (ionChange)="changeGoal($any($event))"
            >
              <ion-select-option [value]="Goal.PREVENT">Prevent</ion-select-option>

              <ion-select-option [value]="Goal.PLAN">Plan</ion-select-option>

              <ion-select-option [value]="Goal.PREGNANT">Pregnant</ion-select-option>

              <ion-select-option [value]="Goal.RECOVERY">Recovery</ion-select-option>

              <ion-select-option [value]="Goal.POSTPARTUM">Postpartum</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-item-group>
      }

      <!-- Hardware ID -->
      @if (personas && observables.accountId) {
        <ion-item-group>
          <ion-item-divider>
            <ion-label>Hardware 🌡</ion-label>
          </ion-item-divider>

          <ion-item>
            <ion-select
              label="Hardware Id"
              [value]="observables.hardwareId"
              (ionChange)="toggleHardwareId($any($event))"
            >
              <ion-select-option [value]="HardwareId.ORAL_THERMOMETER">Manual</ion-select-option>

              <ion-select-option [value]="HardwareId.UEBE_THERMOMETER">Uebe (T2)</ion-select-option>

              <ion-select-option [value]="HardwareId.OURA">Oura</ion-select-option>

              <ion-select-option [value]="HardwareId.T3_THERMOMETER">T3</ion-select-option>

              <ion-select-option [value]="HardwareId.APPLE_WATCH">Apple Watch</ion-select-option>
            </ion-select>
          </ion-item>

          @if (showDangerousFeatures) {
            <ion-item>
              <ion-select
                label="Buy thermometer"
                [value]="undefined"
                (ionChange)="buyThermometer($any($event))"
              >
                <ion-select-option [value]="ProductKey.THERMOMETER">T1</ion-select-option>

                <ion-select-option [value]="ProductKey.BT_THERMOMETER">T2</ion-select-option>

                <ion-select-option [value]="ProductKey.T3_THERMOMETER">T3</ion-select-option>
              </ion-select>
            </ion-item>
          }

          <ion-item button (click)="qaService.getAllHardwares()">
            <ion-label>Make all hardwares available</ion-label>
          </ion-item>
        </ion-item-group>
      }

      <!-- Partners -->
      @if (observables.completeDate) {
        <ion-item-group>
          <ion-item-divider>
            <ion-label>Partners</ion-label>
          </ion-item-divider>

          @for (partner of partners$ | async; track partner.id) {
            <ion-item button (click)="loginToPartner(partner.id)">
              <ion-label>{{ partner.email }}</ion-label>
            </ion-item>
          }

          <ion-item button (click)="addPartner()">
            <ion-label>Add partner</ion-label>
          </ion-item>

          <ion-item button (click)="loginToPartner()">
            <ion-label>Login to partner</ion-label>
          </ion-item>
        </ion-item-group>
      }

      <!-- Oura -->
      @if (personas && observables.accountId && observables.hardwareId === HardwareId.OURA) {
        <ion-item-group>
          <ion-item-divider>
            <ion-label>Oura 💍</ion-label>
          </ion-item-divider>

          @if (!observables.ouraAuthorized) {
            <ion-item (click)="qaService.connectToOuraMockedAccount()" button>
              <ion-label>Connect to mocked Oura account</ion-label>
            </ion-item>
          }

          <ion-item (click)="qaService.goToOuraAuth()" button>
            <ion-label>Oura Cloud auth</ion-label>
          </ion-item>

          <ion-item (click)="syncOuraData()" button>
            <ion-label>Sync Oura Data</ion-label>
          </ion-item>

          <ion-item (click)="syncOuraData(OuraDataType.ADJUSTED)" button>
            <ion-label>Sync Adjusted Data</ion-label>
          </ion-item>

          <ion-item (click)="syncOuraData(OuraDataType.SHORT_SLEEP)" button>
            <ion-label>Sync Short Sleep Data</ion-label>
          </ion-item>

          <ion-item (click)="syncOuraData(OuraDataType.EXCLUDED)" button>
            <ion-label>Sync Excluded Temperature Data</ion-label>
          </ion-item>

          <ion-item (click)="disconnectOuraAccount()" button>
            <ion-label>Disconnect Oura Account</ion-label>
          </ion-item>
        </ion-item-group>
      }

      <!-- Apple Watch -->
      @if (personas && observables.accountId && observables.hardwareId === HardwareId.APPLE_WATCH) {
        <ion-item-group>
          <ion-item-divider>
            <ion-label>Apple Watch ⌚</ion-label>
          </ion-item-divider>

          <ion-item (click)="addAppleWatchData(1)" button>
            <ion-label>Add data for today</ion-label>
          </ion-item>

          <ion-item (click)="addAppleWatchData()" button>
            <ion-label>Add data for multiple days</ion-label>
          </ion-item>

          <ion-item (click)="resetWristTemperatureMock()" button>
            <ion-label>Reset mocked data</ion-label>
          </ion-item>

          <ion-item (click)="deleteHKCache()" button>
            <ion-label>Delete HK Cache</ion-label>
          </ion-item>
        </ion-item-group>
      }

      <!-- Samsung ring -->
      @if (personas && observables.accountId) {
        <ion-item-group>
          <ion-item-divider>
            <ion-label>Samsung ring 💍</ion-label>
          </ion-item-divider>

          <ion-item
            (click)="checkIfAppIsInstalled(ExternalApp.ANDROID_SAMSUNG_WEARABLE_OLD)"
            button
          >
            <ion-label>Check if wear app is installed</ion-label>
          </ion-item>

          <ion-item (click)="checkIfAppIsInstalled(ExternalApp.ANDROID_SAMSUNG_WEARABLE)" button>
            <ion-label>Check if wear2 app is installed</ion-label>
          </ion-item>

          <ion-item (click)="checkIfAppIsInstalled(ExternalApp.ANDROID_SAMSUNG_HEALTH)" button>
            <ion-label>Check if SH app is installed</ion-label>
          </ion-item>

          <ion-item (click)="checkIfAppIsInstalled(ExternalApp.ANDROID_SAMSUNG_RING_PLUGIN)" button>
            <ion-label>Check if ring plugin is installed</ion-label>
          </ion-item>
        </ion-item-group>
      }

      <ion-item-group class="ion-no-padding">
        <ion-item (click)="qaService.toggleLagRadar()" button>
          <ion-label>Toggle lag radar</ion-label>
        </ion-item>

        <ion-item (click)="showPerf()" button>
          <ion-label>Perf</ion-label>
        </ion-item>

        <ion-item (click)="triggerError()" button>
          <ion-label>Trigger error</ion-label>
        </ion-item>

        <ion-item (click)="triggerErrorDialog()" button>
          <ion-label>Trigger error dialog</ion-label>
        </ion-item>

        <!-- Uncomment to show the feature flags section -->

        <!-- <ion-item>
        <ion-select
          label="Feature flags"
          [multiple]="true"
          [value]="enabledFeatureFlags$ | async"
          (ionChange)="changeFeatureFlag($any($event))"
        >
            <ion-select-option [value]="Experiment.<Name of the experiment>"
              >Name of the feature flag</ion-select-option
            >
        </ion-select>
      </ion-item> -->
      </ion-item-group>

      <!-- Personas -->
      @if (personas) {
        <ion-item-group>
          <ion-item-divider>
            <ion-label>Personas</ion-label>
          </ion-item-divider>

          @for (persona of personas; track persona.code) {
            <ion-item (click)="loginToPersona(persona.code)" button>
              <ion-label>{{ persona.name1 }}</ion-label>
              <ion-note slot="end" color="text">{{ persona.descr }}</ion-note>
            </ion-item>
          }
        </ion-item-group>
      }

      <ion-item (click)="logoutFromRedDot()" button>
        <ion-label>Logout from RedDot</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
}
