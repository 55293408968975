import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { ROUTES } from '@app/cnst/nav.cnst'
import { NotificationsPermissionModal } from '@app/modals/notifications-permission/notifications-permission.modal'
import { di } from '@app/srv/di.service'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { tr } from '@app/srv/translation.util'
import { App } from '@capacitor/app'
import { PermissionState } from '@capacitor/core/types/definitions'
import { LocalNotifications } from '@capacitor/local-notifications'
import { NavController } from '@ionic/angular'
import { _stringMapValues, pDefer, StringMap } from '@naturalcycles/js-lib'
import { ProductKey, Reminders } from '@naturalcycles/shared'
import { dayjs } from '@naturalcycles/time-lib'
import { env } from '@src/environments/environment'
import { NativeSettings } from '@src/typings/capacitor'
import { BehaviorSubject } from 'rxjs'
import { MoEngageService } from '../analytics/moengage.service'
import { FEEDBACK } from '../cnst/feedback.cnst'
import { isAndroidApp, isIOSApp } from '../cnst/userDevice.cnst'
import { FeedbackModal } from '../modals/feedback/feedback.modal'
import { UserSettings } from '../reducers/userSettings.reducer'
import { LocalNotificationService } from './notification.local.service'
import { PushNotificationService } from './notification.push.service'
import { dispatch, getState } from './store.service'

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private popupController = inject(PopupController)
  private analyticsService = inject(AnalyticsService)
  private localNotificationService = inject(LocalNotificationService)
  private pushNotificationService = inject(PushNotificationService)
  private moEngageService = inject(MoEngageService)

  public permission$ = new BehaviorSubject<boolean>(false)

  public async available(): Promise<boolean> {
    const state = await this.getPermissionState().catch(() => false)

    return !!state
  }

  public async init(): Promise<void> {
    // enable educational content if it is undefined
    const { notifications } = getState()
    if (notifications.settings?.educationalContent === undefined) {
      dispatch('extendNotificationSettings', { educationalContent: true })
    }

    const hasPermission = await this.hasPermission()

    if (hasPermission) {
      this.localNotificationService.init()
      this.pushNotificationService.addListeners()

      void this.moEngageService.initPush()
    }

    await this.clearAllNotifications()
  }

  public async getPermissionState(): Promise<PermissionState> {
    const { display } = await LocalNotifications.checkPermissions().catch(() => ({
      display: 'denied' as PermissionState,
    }))

    this.permission$.next(display === 'granted')

    return display
  }

  public async hasPermission(): Promise<boolean> {
    const state = await this.getPermissionState()

    return state === 'granted'
  }

  public async registerPermission(partner?: boolean): Promise<boolean> {
    const hasPermission = await this.localNotificationService.registerPermission()

    void this.analyticsService.trackEvent(
      hasPermission ? EVENT.NOTIFICATIONS_ALLOWED : EVENT.NOTIFICATIONS_DECLINED,
    )

    void this.moEngageService.trackPushPermission(hasPermission)

    if (hasPermission) {
      if (!partner) this.localNotificationService.init()

      this.pushNotificationService.addListeners()
      void this.moEngageService.initPush()
    }

    this.permission$.next(hasPermission)

    return hasPermission
  }

  public async openNotificationsModal(
    props?: StringMap,
    partner?: boolean,
    awaitDismiss?: boolean,
  ): Promise<void> {
    const available = await this.available()

    if (!available) return

    const state = await this.getPermissionState()

    if (!state.startsWith('prompt')) return

    const modal = await this.popupController.presentModal(
      {
        component: NotificationsPermissionModal,
        componentProps: props,
        cssClass: 'modal--alwaysOnTop',
      },
      'modal-notificationsPermission',
    )

    const permissionRegistered = pDefer()

    void modal.onDidDismiss().then(data => {
      const { data: enableNotifications } = data || {}

      if (!enableNotifications) {
        permissionRegistered.resolve()
        return
      }

      void this.registerPermission(partner).then(() => permissionRegistered.resolve())
    })

    if (awaitDismiss) {
      await permissionRegistered
    }
  }

  // Return true/false depending on users answer
  public async showEnableNotificationsAlert(): Promise<boolean> {
    const accepted = await this.showAlert(
      'reminders-enable-title',
      'reminders-enable-txt',
      'reminders-btn-accept',
      'reminders-btn-reject',
    )

    void this.analyticsService.trackEvent(EVENT.REMINDERS_NOTIFICATION_QUESTION, {
      answer: accepted,
    })

    if (!accepted) return false

    return await this.registerPermission()
  }

  // User has disabled notifications on device, go to settings
  public async showNotificationsDisabledAlert(
    skipAppSettings = false,
    isOura = false,
  ): Promise<boolean> {
    const accepted = isOura
      ? await this.showAlert(
          'txt-no-notifications-title',
          'txt-no-notifications-body',
          'txt-yes-enable',
          'txt-no-thanks',
        )
      : await this.showAlert(
          'reminders-disabled-title',
          'reminders-disabled-txt',
          'reminders-btn-accept',
          'reminders-btn-reject',
        )

    if (!accepted) {
      return false
    }

    if (!skipAppSettings) {
      await di.get(NavController).navigateForward(ROUTES.SettingsNotificationsPage)
      return true
    }

    if (isIOSApp) {
      await NativeSettings.open()
    } else if (isAndroidApp) {
      await NativeSettings.openNotificationSettings()
    }

    return true
  }

  // Called from Add Data
  public async checkCRM(): Promise<boolean> {
    const { account, userFertility, userSettings } = getState()

    if (userSettings.declinedCRMAlert) return false

    const available = await this.available()
    const state = await this.getPermissionState()

    if (!available || state === 'granted') return false

    const daysRegistered = dayjs().diff(dayjs(account.regDate), 'day')
    const entriesAdded = Object.keys(userFertility.entryMap).length
    const minDays = account.plan === ProductKey.TRIAL ? 3 : 6

    if (!state.startsWith('prompt') || daysRegistered < minDays || entriesAdded < minDays) {
      return false
    }

    this.showCRMAlert()

    return true
  }

  public async clearAllNotifications(): Promise<void> {
    const { isActive } = await App.getState()

    if (!isActive) return

    await LocalNotifications.removeAllDeliveredNotifications()
  }

  private showCRMAlert(): void {
    void this.showAlert('push-question-title', 'push-question-txt', 'txt-ok', 'txt-no-thanks').then(
      accepted => {
        if (accepted) {
          void this.registerPermission()
          void this.analyticsService.trackEvent(EVENT.NOTIFICATION_QUESTION, {
            answer: true,
          })
        } else {
          dispatch('extendUserSettings', { declinedCRMAlert: true })
          void this.analyticsService.trackEvent(EVENT.NOTIFICATION_QUESTION, {
            answer: false,
          })
        }
      },
    )
  }

  private async showAlert(
    title: string,
    message: string,
    accept: string,
    cancel: string,
  ): Promise<boolean> {
    return await new Promise<boolean>(resolve => {
      void this.popupController.presentAlert(
        {
          header: tr(title),
          message: tr(message),
          buttons: [
            {
              text: tr(cancel),
              role: 'cancel',
              handler: () => {
                resolve(false)
              },
            },
            {
              text: tr(accept),
              handler: () => {
                resolve(true)
              },
            },
          ],
          backdropDismiss: false,
        },
        `alert-${title}`,
      )
    })
  }

  public async showFertilityNotificationFeedback(
    pushNotifications: Reminders,
    userSettings: UserSettings,
  ): Promise<void> {
    let count = userSettings.appOpenAfterBgrSyncCount || 0
    const maxCount = env.prod ? 5 : 1 // 5 times for prod, 1 time for dev

    if (count >= maxCount || !pushNotifications.fertilityStatus) return

    // increase count
    count++
    dispatch('extendUserSettings', { appOpenAfterBgrSyncCount: count })

    if (count < maxCount) return

    const feedbackModal = await this.popupController.presentModal(
      {
        component: FeedbackModal,
        cssClass: ['modal--alert'],
        componentProps: FEEDBACK.FERTILITY_NOTIFICATION,
      },
      'modal-feedback',
      Priority.HIGH,
    )

    const { data } = await feedbackModal.onDidDismiss()

    if (!data || data === 'skip') return

    // only popup second feedback if there's at least one negative feedback
    if (!_stringMapValues(data).includes(false)) return

    await this.popupController.presentModal(
      {
        component: FeedbackModal,
        cssClass: ['modal--transparent', 'modal--alert', 'modal--keyboard'],
        componentProps: FEEDBACK.FERTILITY_NOTIFICATION_INPUT,
      },
      'modal-feedback',
      Priority.HIGH,
    )
  }
}
