import { Injectable } from '@angular/core'
import { ExternalPersonalId } from '@naturalcycles/shared'
import { env } from '@src/environments/environment'
import {
  MoECapacitorCore,
  MoEInitConfig,
  MoEProperties,
  MoEPushCampaignData,
} from 'capacitor-moengage-core'
import { isAndroidApp, isIOSApp, isWebApp } from '../cnst/userDevice.cnst'
import { di } from '../srv/di.service'
import { logUtil } from '../util/log.util'
import { EVENT } from './analytics.cnst'
import { AnalyticsService } from './analytics.service'

const appId = env.moEngageAppId
const disabled = isWebApp || !appId

@Injectable({ providedIn: 'root' })
export class MoEngageService {
  public async init(): Promise<void> {
    if (disabled) return

    const initConfig: MoEInitConfig = {
      analyticsConfig: {
        shouldTrackUserAttributeBooleanAsNumber: true,
      },
    }

    try {
      await MoECapacitorCore.initialize({ appId, initConfig })
    } catch (err) {
      this.logMoError(err)
    }
  }

  public async setUniqueId(uniqueId: ExternalPersonalId): Promise<void> {
    if (disabled) return

    try {
      await MoECapacitorCore.setUniqueId({ uniqueId, appId })
    } catch (err) {
      this.logMoError(err)
    }
  }

  public async trackEvent(eventName: string, eventAttributes?: MoEProperties): Promise<void> {
    if (disabled) return

    try {
      await MoECapacitorCore.trackEvent({ eventName, eventAttributes, appId })
    } catch (err) {
      this.logMoError(err)
    }
  }

  public async initPush(): Promise<void> {
    if (disabled) return

    if (isIOSApp) {
      try {
        await MoECapacitorCore.registerForPush()
      } catch (err) {
        this.logMoError(err)
      }
    }

    try {
      await MoECapacitorCore.addListener('pushClicked', (data: MoEPushCampaignData) => {
        di.get(AnalyticsService).trackEvent('MoEngage push clicked' as EVENT, {
          data,
        })
      })
    } catch (err) {
      this.logMoError(err)
    }

    if (isAndroidApp) {
      try {
        await MoECapacitorCore.setupNotificationChannelsAndroid()
      } catch (err) {
        this.logMoError(err)
      }
    }
  }

  /** Android only */
  public async registerDeviceToken(token: string): Promise<void> {
    if (disabled) return
    if (!isAndroidApp) return

    try {
      await MoECapacitorCore.passFcmPushToken({ token, appId })
    } catch (err) {
      this.logMoError(err)
    }
  }

  /** Android only */
  public async trackPushPermission(isGranted: boolean): Promise<void> {
    if (disabled) return
    if (!isAndroidApp) return

    try {
      await MoECapacitorCore.pushPermissionResponseAndroid({ isGranted })
    } catch (err) {
      this.logMoError(err)
    }
  }

  public async resetUser(): Promise<void> {
    if (disabled) return

    try {
      await MoECapacitorCore.logoutUser({ appId })
    } catch (err) {
      this.logMoError(err)
    }
  }

  private logMoError(err: any): void {
    logUtil.log('MoEngage error')
    logUtil.error(err)
  }
}
